import { Button, DownloadIcon, Typography, theme } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Card, CardContent, CardHeader, CardTitle } from "../../../../00_shared/components/Card"
import { Dialog, DialogContent } from "../../../../00_shared/components/Dialog"
import useMediaQuery from "../../../../00_shared/hooks/useMediaQuery.hook"
import { useDashboardTranslation } from "../../../../01_technical/translations"

const CardContainer = styled(Card)`
  @media (max-width: 768px) {
    padding: 0;
    border: none !important;
  }
`

const CardHeaderContainer = styled(CardHeader)`
  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const CardContentContainer = styled(CardContent)`
  padding: 0;
`

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  text-transform: uppercase;
  background-color: ${theme.colors.grey.$100};
  border-bottom: 1px solid ${theme.colors.grey.$200};

  @media (max-width: 768px) {
    display: none;
  }
`

const SectionHeaderText = styled(Typography).attrs(() => ({
  $variant: "label-1",
}))`
  color: ${theme.colors.grey.$500};
`

const StatementList = styled.ul`
  list-style: none;
  padding: 1rem 1.5rem 0 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0;

  @media (max-width: 768px) {
    padding: 1rem 0 0 0;
  }
`

const StatementItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;

  border-bottom: 1px solid ${theme.colors.grey.$200};

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const StatementInfo = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: baseline;
`

const StatementDate = styled(Typography).attrs(() => ({
  $variant: "body-3-medium",
}))`
  color: ${theme.colors.grey.$600};
`

const StatementAccounts = styled(Typography).attrs(() => ({
  $variant: "body-4-regular",
}))`
  color: ${theme.colors.grey.$600};
`

const DownloadButton = styled(Button).attrs(() => ({
  size: "small",
  $variant: "ghost",
}))`
  background: none;
  color: ${theme.colors.grey.$600};
  min-width: unset;
  padding: 0;

  &:hover,
  &:focus {
    background: none;
    color: ${theme.colors.grey.$600};
  }
`

const StatementsCard = () => {
  const { t } = useDashboardTranslation()
  const isMobile = useMediaQuery("(max-width: 768px)")

  const statements = [
    { date: "novembre 2022", accounts: 7 },
    { date: "octobre 2022", accounts: 7 },
    { date: "septembre 2022", accounts: 7 },
    { date: "aout 2022", accounts: 7 },
    { date: "juillet 2022", accounts: 7 },
  ]

  return (
    <CardContainer>
      <CardHeaderContainer>
        <CardTitle size={isMobile ? "large" : "medium"}>{t("accounts.details.statements.header")}</CardTitle>
      </CardHeaderContainer>
      <CardContentContainer>
        <SectionHeader>
          <SectionHeaderText>{t("accounts.details.statements.sectionHeader")}</SectionHeaderText>
        </SectionHeader>

        <StatementList>
          {statements.map((statement, index) => (
            <StatementItem key={index}>
              <StatementInfo>
                <StatementDate>{statement.date}</StatementDate>
                {"-"}
                <StatementAccounts>{`${statement.accounts} ${t("accounts.details.statements.accounts")}`}</StatementAccounts>
              </StatementInfo>
              <DownloadButton>
                <DownloadIcon />
              </DownloadButton>
            </StatementItem>
          ))}
        </StatementList>
      </CardContentContainer>
    </CardContainer>
  )
}

export const AccountDetailsBankStatements = () => {
  const isMobile = useMediaQuery("(max-width: 768px)")
  const navigate = useNavigate()

  const preventAutoFocus = (event: Event) => {
    event.preventDefault()
  }
  const handleCloseOverlay = () => {
    navigate("..")
  }

  return (
    <>
      {!isMobile && <StatementsCard />}
      {isMobile && (
        <Dialog open={true} onOpenChange={handleCloseOverlay}>
          <DialogContent onOpenAutoFocus={preventAutoFocus} $disableAnimations={true} width="100%" height="100%">
            <StatementsCard />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
