import { gql, useApolloClient } from "@apollo/client"
import { toaster } from "@hero/krypton"
import { useState } from "react"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { GET_CREDIT_WIRE_TRANSFER_DOCUMENT_UPLOAD_URL_RESPONSE } from "./useApplyForCreditWireTransfer"

export const useUploadFile = ({ onUploadSuccess }: { onUploadSuccess: (fp: string) => void }) => {
  const client = useApolloClient()
  const { t } = useDashboardTranslation()

  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [uploadedFilePath, setFilePath] = useState<string | undefined>(undefined)
  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined)

  const uploadFile = async (file: File) => {
    try {
      if (file.type !== "application/pdf") {
        return toaster.error(t("creditWireTransfer.applicationForm.upload.errorBadFileType"))
      }

      setIsUploading(true)
      setFilePath(undefined)

      const { signedUrl, filePath } = await getUploadUrl()

      if (!signedUrl || !filePath) {
        throw new Error(t("creditWireTransfer.applicationForm.upload.error"))
      }

      const uploadResult = await uploadRequest(signedUrl, file)

      if (!uploadResult.ok) {
        throw new Error(t("creditWireTransfer.applicationForm.upload.error"))
      }

      toaster.success(t("creditWireTransfer.applicationForm.upload.success"))
      setFilePath(filePath)
      setUploadedFile(file)
      onUploadSuccess(filePath)
    } catch (err: unknown) {
      toaster.error(t("creditWireTransfer.applicationForm.upload.error"))
      throw err
    } finally {
      setIsUploading(false)
    }
  }

  const getUploadUrl = async () => {
    const { data } = await client.query<GET_CREDIT_WIRE_TRANSFER_DOCUMENT_UPLOAD_URL_RESPONSE>({
      query: UPLOAD_CWT_DOCUMENT_REQ,
      fetchPolicy: "network-only",
    })

    return {
      signedUrl: data?.creditWireTransferDocumentUploadUrl?.signedUrl,
      filePath: data?.creditWireTransferDocumentUploadUrl?.path,
    }
  }

  const uploadRequest = async (signedUrl: string, file: File) => {
    const uploadResult = await fetch(signedUrl, {
      method: "PUT",
      headers: { "Content-Type": file.type, "Content-Length": file.size.toString() },
      body: file,
    })

    return uploadResult
  }

  return { isUploading, uploadedFilePath, uploadFile, uploadedFile }
}

const UPLOAD_CWT_DOCUMENT_REQ = gql`
  query creditWireTransferDocumentUploadUrl {
    creditWireTransferDocumentUploadUrl {
      signedUrl
      path
    }
  }
`
