import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { IssuingCard } from "../00_shared/business/IssuingCard"
import { IssuingTransaction } from "../00_shared/business/IssuingTransaction"

const GET_MERCHANT_OPERATIONS = gql`
  query listIssuingCards($id: String!) {
    listCards(pagination: { limit: 1, page: 1 }, filters: { ids: [$id] }) {
      ... on ListIssuedCardsResult {
        cards {
          id
          merchantId
          businessAccountId
          issuanceType
          status
          expiration
          lastCardDigits
          displayLastCardDigits
          allowContactless
          allowEcommerce
          allowOtherCurrency
          cardholderName
          monthlySpendingLimitAmount
          consumedMonthlySpendingLimitAmount
          monthlySpendingLimitAmountEuros
          consumedMonthlySpendingLimitAmountEuros
          paymentAccountName
          pending3dsChallengeId
          formattedOrderedDate
          formattedProductionDate
          formattedReceptionDate
          isDeliveryLate
          notReceivedDeclarationDate
        }
        total
      }
      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

const GET_CARD_RECENT_TRANSACTIONS = gql`
  query listIssuingCardRecentTransactions($cardId: String!) {
    listIssuingCardRecentTransactions(cardId: $cardId) {
      ... on ListIssuingCardRecentTransactionsOutput {
        operations {
          id
          amountEuros
          label
          operationType
          createdAt
          createdAtFormatted
        }
      }
    }
  }
`

export const useIssuingCardDetailsHook = (id?: string) => {
  const card = useHeroQuery<{ cards: IssuingCard[] }>({
    gqlQuerySchema: GET_MERCHANT_OPERATIONS,
    fetchPolicy: "cache-and-network",
    variables: {
      id,
    },
  })

  const transactions = useHeroQuery<{ operations: IssuingTransaction[] }>({
    gqlQuerySchema: GET_CARD_RECENT_TRANSACTIONS,
    fetchPolicy: "cache-and-network",
    variables: {
      cardId: id,
    },
    skip: !id,
  })

  return {
    card: card.data?.cards?.[0] || undefined,
    transactions: transactions.data?.operations ? transactions.data.operations : [],

    loading: card.loading || transactions.loading,
    error: card.error || transactions.error,
    refetch: () => {
      card.refetch()
      transactions.refetch()
    },
  }
}
