import { Button, Typography } from "@hero/krypton"
import styled from "styled-components"
import useMediaQuery from "../hooks/useMediaQuery.hook"

const EmptyStateContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$300};
  box-shadow: ${({ theme }) => theme.shadows.mid};
  padding: 5rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    align-items: start;
    padding: 1rem;
    border: none;
    box-shadow: none;
  }
`

const EmptyStateContent = styled.div<{ width?: string }>`
  width: ${({ width }) => width || "50%"};
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: start;
  text-align: start;

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
    align-items: center;
  }
`

const CardImageWrapper = styled.div`
  width: 6.25rem;
  height: 6.25rem;
`

const EmptyStateTitle = styled(Typography).attrs(() => ({
  $variant: "title-2-bold",
}))``

const EmptyStateText = styled(Typography).attrs(() => ({
  $variant: "body-4-regular",
}))`
  padding-bottom: 1rem;
`

const EmptyStateButton = styled(Button).attrs(({ size }) => ({
  variant: "primary",
  size: size || "medium",
}))`
  @media (max-width: 768px) {
    width: 100%;
  }
`

type EmptyStateProps = {
  illustration: React.ReactNode
  title: string
  text: string
  buttonIcon?: React.ReactNode
  buttonText: string
  onButtonClick?: () => void
  width?: string
  disabledButton?: boolean
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  illustration,
  title,
  text,
  buttonIcon,
  buttonText,
  onButtonClick,
  width,
  disabledButton,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)")

  return (
    <EmptyStateContainer>
      <EmptyStateContent width={width}>
        <CardImageWrapper>{illustration}</CardImageWrapper>
        <EmptyStateTitle>{title}</EmptyStateTitle>
        <EmptyStateText>{text}</EmptyStateText>
        <EmptyStateButton size={isMobile ? "large" : "medium"} onClick={onButtonClick} disabled={disabledButton}>
          {buttonIcon ? buttonIcon : null}
          {buttonText}
        </EmptyStateButton>
      </EmptyStateContent>
    </EmptyStateContainer>
  )
}
