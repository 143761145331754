import { Typography } from "@hero/krypton"
import styled from "styled-components"
import { FlexContainer } from "../../../../00_shared/components/Flex"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { IssuingCard } from "../business/IssuingCard"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: fit-content;
  gap: 12px;
  width: 100%;
`

const ProgressSection = styled.div`
  position: relative;
  width: 100%;
`

const Progress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
  gap: 4px;
`

const ProgressLine = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #e9eaf0;
  display: flex;
  align-items: center;
`

const ProgressLineProgression = styled.div<{ consumed: number; total: number }>`
  width: ${(props) => `${Math.max(0, Math.min(100, props.consumed / props.total))}%`};
  height: 4px;
  border-radius: 4px;
  background-color: #1d1e21;
`

const Balance = styled(Typography)`
  position: absolute;
  top: -0.7rem;
`

interface SpendingLimitProps {
  card: IssuingCard
}

const SpendingLimit = ({ card }: SpendingLimitProps) => {
  const { t } = useDashboardTranslation()

  return (
    <Content>
      <Typography $variant="label-1" $color="grey.$500">
        {(t("issuing.card.cardType.debit") as string).toUpperCase()}
      </Typography>
      <ProgressSection>
        <Balance $variant="title-3-semibold" $color="grey.$600">
          {toEuros(card.consumedMonthlySpendingLimitAmount)}
        </Balance>

        <Progress>
          <FlexContainer $gap=".3rem">
            <Typography $variant="caption-2" $color="grey.$500">
              {t("issuing.card.limits.spendingLimits.available")}
            </Typography>
            <Typography $variant="caption-2" $color="grey.$500">
              {toEuros(Math.max(0, card.monthlySpendingLimitAmount - card.consumedMonthlySpendingLimitAmount))}
            </Typography>
          </FlexContainer>
          <ProgressLine>
            <ProgressLineProgression
              consumed={card.consumedMonthlySpendingLimitAmount}
              total={card.monthlySpendingLimitAmount}
            />
          </ProgressLine>
          <FlexContainer $gap=".3rem">
            <Typography $variant="caption-2" $color="grey.$500">
              {t("issuing.card.limits.spendingLimits.maxLimit")}
            </Typography>
            <Typography $variant="caption-2" $color="grey.$500">
              {toEuros(card.monthlySpendingLimitAmount)}
            </Typography>
          </FlexContainer>
        </Progress>
      </ProgressSection>
    </Content>
  )
}

export default SpendingLimit
