import { DateTime } from "luxon"
import { CommonTFunction } from "../../../../01_technical/translations"
import { UserLocale } from "../../../../business/enums/User.enum"
import { OperationType } from "../../00_shared/business"
import { OperationDetails } from "../00_shared/hooks/useOperationDetails"

export const mapTypeToOperationTypes = (type: string): OperationType[] => {
  switch (type) {
    case "incoming":
      return [OperationType.SEPA_CREDIT_TRANSFER_IN, OperationType.ACCOUNT_TO_ACCOUNT_IN, OperationType.CARD_REFUND]
    case "outgoing":
      return [OperationType.ACCOUNT_TO_ACCOUNT_OUT, OperationType.SEPA_CREDIT_TRANSFER_OUT]
    case "cancelled":
      return [OperationType.CARD_REFUND]
    default:
      return []
  }
}

export const mapMethodToOperationTypes = (method: string): OperationType[] => {
  switch (method) {
    case "transfer":
      return [
        OperationType.SEPA_CREDIT_TRANSFER_IN,
        OperationType.SEPA_CREDIT_TRANSFER_OUT,
        OperationType.ACCOUNT_TO_ACCOUNT_IN,
        OperationType.ACCOUNT_TO_ACCOUNT_OUT,
      ]
    case "card":
      return [OperationType.CARD_PAYMENT, OperationType.CARD_REFUND]
    case "debit":
      return [] // Add corresponding OperationTypes for "debit" if they exist
    default:
      return []
  }
}

interface OperationItem {
  label: string
  value: string
}

export interface FormattedDetails {
  operationItems: OperationItem[]
  createdAt: string
  status:
    | {
        label: string
        value: string | undefined
      }[]
    | undefined
  label: string
  pageTitle: string
  amount: string
}

export const formatDetails = (data: OperationDetails, t: CommonTFunction, local: UserLocale): FormattedDetails => {
  return {
    operationItems: [
      {
        label: `${t("operation.details.transaction")}`,
        value: getLabel(data),
      },
      {
        label: `${t("operation.details.reference")}`,
        value: data.reference || "-",
      },
      { label: `${t("operation.details.method")}`, value: `${t("operation.details.transfer")}` },
    ],
    createdAt: DateTime.fromJSDate(new Date(data.createdAt)).setLocale(local).toLocaleString(DateTime.DATE_MED),
    status: getStatus(data, t, local),
    label: getLabel(data),
    pageTitle: t("account.operation.details.title"),
    amount: new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(data.amount / 100),
  }
}

const getLabel = (data: OperationDetails) => {
  if (
    data.operationType === OperationType.ACCOUNT_TO_ACCOUNT_OUT ||
    data.operationType === OperationType.SEPA_CREDIT_TRANSFER_OUT
  ) {
    return data.creditorName
  }

  return data.debtorName
}

const getStatus = (data: OperationDetails, t: CommonTFunction, local: UserLocale) => {
  // if (
  //   data.operationType === OperationType.ACCOUNT_TO_ACCOUNT_IN ||
  //   data.operationType === OperationType.SEPA_CREDIT_TRANSFER_IN
  // ) {
  //   return undefined
  // }

  return [
    {
      label: `${t("operation.details.status.initiate")}`,
      value: data.status.initiateAt
        ? DateTime.fromJSDate(new Date(data.status.initiateAt))
            .setLocale(local)
            .toLocaleString(DateTime.TIME_24_WITH_SECONDS)
        : undefined,
    },
    {
      label: `${t("operation.details.status.process")}`,
      value: data.status.processAt
        ? DateTime.fromJSDate(new Date(data.status.processAt))
            .setLocale(local)
            .toLocaleString(DateTime.TIME_24_WITH_SECONDS)
        : undefined,
    },
    {
      label: `${t("operation.details.status.payout")}`,
      value: data.status.payoutAt
        ? DateTime.fromJSDate(new Date(data.status.payoutAt))
            .setLocale(local)
            .toLocaleString(DateTime.TIME_24_WITH_SECONDS)
        : undefined,
    },
  ]
}
