import { gql } from "@apollo/client"

export const UPDATE_MERCHANT_USER = gql`
  mutation updateMerchantUser($email: String!, $firstname: String!, $lastname: String!, $phone: String!) {
    updateMerchantUser(user: { email: $email, firstname: $firstname, lastname: $lastname, phone: $phone }) {
      ... on MerchantUpdateUser {
        id
        email
        firstname
        lastname
        phone
      }

      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

export type UPDATE_MERCHANT_RESPONSE = {
  updateMerchantUser: {
    id: string
    email: string
    firstname: string
    lastname: string
    phone: string
  }
}

export type UPDATE_MERCHANT_VARIABLES = {
  email: string
  firstname: string
  lastname: string
  phone: string
}
