import { gql } from "@apollo/client"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { OperationType } from "../../../00_shared/business"

const GET_OPERATION_DETAILS = gql`
  query GetOperationDetails($operationId: String!) {
    getOperationDetails(operationId: $operationId) {
      ... on GetOperationDetailsOutput {
        id
        label
        debtorName
        creditorName
        amount
        createdAt
        reference
        operationType
        justificativePath
        note
        status {
          initiateAt
          processAt
          payoutAt
        }
      }
      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

interface GetOperationDetailsVariables {
  operationId?: string
}

export interface OperationDetails {
  id: string
  label: string
  debtorName: string
  creditorName: string
  amount: number
  createdAt: string
  reference?: string
  operationType: OperationType
  justificativePath?: string
  note?: string
  status: {
    initiateAt?: Date
    processAt?: Date
    payoutAt?: Date
  }
}

export const useOperationDetails = (operationId?: string) => {
  const [variables, setVariables] = useState<GetOperationDetailsVariables>({ operationId })
  const { transactionId } = useParams<{ transactionId: string }>()

  const { data, refetch, loading, error } = useHeroQuery<OperationDetails>({
    gqlQuerySchema: GET_OPERATION_DETAILS,
    variables,
    fetchPolicy: "cache-and-network",
    skip: !variables.operationId,
  })

  useEffect(() => {
    if (transactionId) setVariables({ operationId: transactionId })
  }, [transactionId])

  return {
    operationDetails: data,
    data,
    loading,
    error,
    refetch,
    variables,
    setVariables,
  }
}
