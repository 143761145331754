import { gradientAnimated } from "@hero/krypton"
import styled from "styled-components"
import { AccountHeader } from "./AccountHeader"
import { AccountSidebar } from "./AccountSidebar/AccountSidebar"

const LayoutContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
  grid-template-columns: 15rem minmax(0, 1fr);
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: 768px) {
    grid-template-columns: minmax(0, 1fr);
  }
`

const GradientLine = styled.div`
  grid-column: 1 / span 2;
  height: 0.125rem;
  width: 100%;
  ${gradientAnimated}
  &::before {
    width: unset;
  }
`

const SidebarWrapper = styled.div`
  grid-row: 2 / span 2;
  grid-column: 1 / 2;
  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: 768px) {
    display: none;
  }
`

const MainContent = styled.div`
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  padding: 1.5rem 3rem;
  display: grid;
  grid-template-rows: minmax(0, min-content);
  min-height: 10rem;

  @media (max-width: 768px) {
    grid-column: 1 / 1;
    padding: 0;
  }
`

export const AccountLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <LayoutContainer>
      <GradientLine />
      <SidebarWrapper>
        <AccountSidebar />
      </SidebarWrapper>
      <AccountHeader />
      <MainContent>{children}</MainContent>
    </LayoutContainer>
  )
}
