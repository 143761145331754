import i18n from "i18next"
import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useIsFFBOV3Active } from "../00_shared/hooks/useFeatureFlag.hook"
import useLocalStorage from "../00_shared/hooks/useLocalStorage.hook"
import { TranslationNamespaces } from "../01_technical/translations"
import { AuthProvider, useAuthContext } from "../Auth/auth.context"
import { FeatureFlagProvider } from "../FeatureFlags/featureFlags.context"
import { MenuLayoutProvider, useMenuLayoutContext } from "./00_shared/components/MenuLayoutContext"
import { BusinessAccountProvider } from "./BusinessAccount/businessAccount.context"
import { DEPRECATED_MENU } from "./DEPRECATED_merchant.menu"
import { Menu } from "./merchant.menu"

function ProtectedRoute({ children }: { children: JSX.Element }) {
  const { currentUser } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!currentUser) {
      navigate("/login")
    }
  }, [currentUser, navigate])

  if (currentUser) {
    return children
  } else {
    return null
  }
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

const Main = styled.main<{ $isMenuReduced: boolean }>`
  margin-left: ${({ $isMenuReduced }) => (!$isMenuReduced ? "15rem" : "4.25rem")};
  transition: margin-left 0.3s;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

const MerchantDashboard = () => {
  const [isBOV3, setIsBOV3] = useLocalStorage("BO_V3", false)
  const isFFBOV3Enabled = useIsFFBOV3Active()
  const { isMenuOpen, isMenuReduced } = useMenuLayoutContext()

  useEffect(() => {
    if (!isFFBOV3Enabled && Boolean(isBOV3)) {
      setIsBOV3(false)
    }
  }, [isBOV3, isFFBOV3Enabled, setIsBOV3])

  if (!isMenuOpen) {
    return <Outlet />
  }

  return (
    <>
      {isBOV3 ? <Menu /> : <DEPRECATED_MENU />}
      <Main $isMenuReduced={isMenuReduced}>
        <Outlet />
      </Main>
    </>
  )
}

export const Merchant = () => {
  i18n.setDefaultNamespace(TranslationNamespaces.DASHBOARD)
  i18n.loadNamespaces([TranslationNamespaces.DASHBOARD])

  return (
    <AuthProvider>
      <FeatureFlagProvider>
        <Container data-test-id="dashboard-merchant">
          <ProtectedRoute>
            <MenuLayoutProvider>
              <BusinessAccountProvider>
                <MerchantDashboard />
              </BusinessAccountProvider>
            </MenuLayoutProvider>
          </ProtectedRoute>
        </Container>
      </FeatureFlagProvider>
    </AuthProvider>
  )
}
