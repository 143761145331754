import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

const SEND_BA_INTERNAL_WIRE = gql`
  mutation SendBAInternalWire($input: SendBAInternalWireInput!) {
    sendBAInternalWire(input: $input) {
      ... on SendBAInternalWireOutput {
        destinationLedgerId
        amount
      }
      ... on SimpleApiError {
        errorCode
      }
      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

type SendBAInternalWireOutput = {
  destinationLedgerId: string
  amount: number
}

type SimpleApiError = {
  errorCode: string
}

type ValidationError = {
  path: string[]
  validationError: string
}

type ValidationErrors = {
  errorCode: string
  validationErrors: ValidationError[]
}

type GqlHeroError = {
  errorCode: string
  message: string
}

type SendBAInternalWireInput = {
  destinationLedgerId: string
  destinationLedgerType: string
  businessAccountLedgerId: string
  amount: number
  reference?: string
}

export const useSendBAInternalWire = () => {
  const [mutate, { data, loading, error }] = useHeroMutation<
    SendBAInternalWireOutput | SimpleApiError | ValidationErrors | GqlHeroError,
    { input: SendBAInternalWireInput }
  >({
    gqlQuerySchema: SEND_BA_INTERNAL_WIRE,
  })

  return { mutate, loading, error, data }
}
