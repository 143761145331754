import { useApolloClient } from "@apollo/client"
import { useEffect, useMemo, useRef, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useMutationWith2fa } from "../Challenge2fa/useMutationWith2fa"
import {
  AUTOLOGIN_DASHBOARD_REQUEST,
  AutologinArgs,
  AutologinResponse,
  AutologinSuccess,
} from "../Login/login.requests"

const isAutologinSuccess = (response: AutologinResponse): response is AutologinSuccess => {
  return (response as AutologinSuccess).autologin.token !== undefined
}

export const Autologin = () => {
  const navigate = useNavigate()
  const client = useApolloClient()
  const [queryParams] = useSearchParams()
  const path = queryParams.get("path")
  const urlToRedirectTo = useMemo(() => path || "/", [path])
  const autologinToken = queryParams.get("autologinToken")
  const autologinEmail = queryParams.get("autologinEmail")
  const [, setOpen2faSetupModel] = useState(false)
  const [autologin] = useMutationWith2fa<AutologinResponse, AutologinArgs>(AUTOLOGIN_DASHBOARD_REQUEST)
  const hasStarted = useRef(false)

  useEffect(() => {
    if (hasStarted.current) {
      return
    }
    hasStarted.current = true

    const auto = async (email: string, autologinToken: string) => {
      try {
        const result = await autologin({
          variables: {
            email,
            autologinToken,
          },
        })
        if (isAutologinSuccess(result.data)) {
          const token = result.data.autologin.token
          localStorage.setItem("token", token)
          // Clear store cache to avoid graphql having cache from another session (AKA another merchant)
          client.clearStore()
          navigate(urlToRedirectTo, { replace: true })
          return
        }
        if (result.data.autologin.errorCode === "2FA_SETUP_REQUIRED") {
          setOpen2faSetupModel(true)
        }
      } catch (e) {
        console.log((e as Error).message)
        window.location.href = "/signin"
      }
    }

    if (!autologinEmail || !autologinToken) {
      return
    }

    auto(autologinEmail, autologinToken)
      .then(() => {
        hasStarted.current = false
      })
      .catch(() => {
        hasStarted.current = false
      })
  }, [autologin, autologinEmail, autologinToken, client, navigate, urlToRedirectTo])

  return <div></div>
}
