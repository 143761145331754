import { RouteObject } from "react-router-dom"
import { Connect } from "../01_technical/client"
import { AuthLayout } from "./auth.layout"
import { OnboardMerchantUserScreen } from "./CreatePassword/onboardMerchantUser.screen"
import { ForgotPassword } from "./ForgotPassword/ForgotPassword"
import { Login } from "./Login/Login.screen"
import { Autologin } from "./LoginAs/Autologin"
import { LoginAs } from "./LoginAs/LoginAs"
import { Logout } from "./Logout/Logout"
import { ResetPassword } from "./ResetPassword/ResetPassword"
import { SignupScreen } from "./Signup/Signup.screen"

export const authRoutes: RouteObject[] = [
  {
    path: "login_as",
    element: (
      <Connect>
        <AuthLayout>
          <LoginAs />
        </AuthLayout>
      </Connect>
    ),
  },
  {
    path: "autologin",
    element: (
      <Connect>
        <AuthLayout>
          <Autologin />
        </AuthLayout>
      </Connect>
    ),
  },
  {
    path: "signup",
    element: (
      <Connect>
        <AuthLayout>
          <SignupScreen />
        </AuthLayout>
      </Connect>
    ),
  },
  {
    path: "login",
    element: (
      <Connect>
        <AuthLayout>
          <Login />
        </AuthLayout>
      </Connect>
    ),
  },
  {
    path: "forgot_password",
    element: (
      <Connect>
        <AuthLayout>
          <ForgotPassword />
        </AuthLayout>
      </Connect>
    ),
  },
  {
    path: "reset_password",
    element: (
      <Connect>
        <AuthLayout>
          <ResetPassword />
        </AuthLayout>
      </Connect>
    ),
  },
  {
    path: "create_password",
    element: (
      <Connect>
        <AuthLayout>
          <OnboardMerchantUserScreen />
        </AuthLayout>
      </Connect>
    ),
  },
  {
    path: "logout",
    element: (
      <Connect>
        <Logout />
      </Connect>
    ),
  },
]
