import { WizardStep } from "@hero/krypton"
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { z, ZodError } from "zod"
import { useCommonTranslation } from "../../../../01_technical/translations"

type TranslationKeys =
  | "transfer.wizard.step.selectTransferType"
  | "transfer.wizard.step.selectAccountToCredit"
  | "transfer.wizard.step.transferInformations"
  | "transfer.wizard.step.confirmTransfer"

interface StepConfig {
  path: string
  translationKey: TranslationKeys
}

const stepsConfig: StepConfig[] = [
  {
    path: "select-transfer-type",
    translationKey: "transfer.wizard.step.selectTransferType",
  },
  {
    path: "select-account-to-credit",
    translationKey: "transfer.wizard.step.selectAccountToCredit",
  },
  {
    path: "transfer-informations",
    translationKey: "transfer.wizard.step.transferInformations",
  },
  {
    path: "confirm-transfer",
    translationKey: "transfer.wizard.step.confirmTransfer",
  },
]

export enum TransferType {
  BENEFICIARY = "BENEFICIARY",
  ACCOUNT_TO_ACCOUNT = "ACCOUNT_TO_ACCOUNT",
}

const transferTypeSchema = z.object({
  selectedTransferType: z.nativeEnum(TransferType),
})

const beneficiarySchema = z.object({
  beneficiaryId: z.string().min(1, "Beneficiary is required"),
  beneficiaryName: z.string().optional(),
  beneficiaryIban: z.string().optional(),
})

const accountToAccountSchema = z.object({
  accountToCreditId: z.string().min(1, "Account is required"),
  accountToCreditName: z.string().optional(),
  accountToCreditIban: z.string().optional(),
})

const transferInformationSchema = z.object({
  amount: z.number().positive("Amount must be greater than zero"),
  accountToDebitId: z.string().min(1, "Account to debit is required"),
  accountToDebitName: z.string().optional(),
  accountToDebitIban: z.string().optional(),
  reference: z.string().min(1, "Reference is required"),
  instantTransfer: z.boolean(),
  beneficiaryEmail: z.string().email().optional(),
  invoice: z.instanceof(File).optional(),
})

const completeFormSchema = transferTypeSchema
  .merge(beneficiarySchema)
  .merge(accountToAccountSchema)
  .merge(transferInformationSchema)

type CreateTransferState = Omit<z.infer<typeof completeFormSchema>, "selectedTransferType"> & {
  selectedTransferType: TransferType | undefined
}

interface CreateTransferContextType {
  state: CreateTransferState
  setState: React.Dispatch<React.SetStateAction<CreateTransferState>>
  steps: WizardStep[]
  setSteps: React.Dispatch<React.SetStateAction<WizardStep[]>>
  errors: Partial<Record<keyof CreateTransferState, string>>
  setErrors: React.Dispatch<React.SetStateAction<Partial<Record<keyof CreateTransferState, string>>>>
  validateStep: (path: string) => boolean
  handleNextStep: () => void
  goToPreviousStep: () => void
  validateAllPreviousSteps: () => void
  setSelectedTransferType: (type: TransferType) => void
}

const CreateTransferContext = createContext<CreateTransferContextType | undefined>(undefined)

export const useCreateTransferContext = () => {
  const context = useContext(CreateTransferContext)
  if (!context) {
    throw new Error("useCreateTransferContext must be used within a CreateTransferProvider")
  }
  return context
}

const initialState: CreateTransferState = {
  selectedTransferType: undefined,
  accountToCreditId: "",
  accountToCreditName: "",
  accountToCreditIban: "",
  beneficiaryId: "",
  beneficiaryName: "",
  beneficiaryIban: "",
  amount: 0,
  accountToDebitId: "",
  accountToDebitName: "",
  accountToDebitIban: "",
  reference: "",
  instantTransfer: true,
}

export const CreateTransferProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useCommonTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const [state, setState] = useState<CreateTransferState>(() => {
    const savedState = sessionStorage.getItem("createTransferState")
    return savedState ? JSON.parse(savedState) : initialState
  })
  const [errors, setErrors] = useState<Partial<Record<keyof CreateTransferState, string>>>({})

  const saveState = useCallback((state: CreateTransferState) => {
    sessionStorage.setItem("createTransferState", JSON.stringify(state))
  }, [])

  const clearState = useCallback(() => {
    sessionStorage.removeItem("createTransferState")
    setState(initialState)
  }, [])

  t("transfer.wizard.step.selectAccountToCredit")
  const generateSteps = useCallback((): WizardStep[] => {
    return stepsConfig.map((stepConfig) => ({
      name: t(stepConfig.translationKey),
      active: location.pathname.includes(stepConfig.path),
      completed: false,
    }))
  }, [location.pathname, t])

  const [steps, setSteps] = useState<WizardStep[]>(generateSteps())

  const validateStep = useCallback(
    (path: string): boolean => {
      try {
        if (path === stepsConfig[0].path) {
          transferTypeSchema.parse(state)
        } else if (path === stepsConfig[1].path) {
          if (state.selectedTransferType === TransferType.ACCOUNT_TO_ACCOUNT) {
            accountToAccountSchema.parse(state)
          } else if (state.selectedTransferType === TransferType.BENEFICIARY) {
            beneficiarySchema.parse(state)
          }
        } else if (path === stepsConfig[2].path) {
          transferInformationSchema.parse(state)
        }
        setErrors({})
        return true
      } catch (error) {
        if (error instanceof ZodError) {
          const fieldErrors = error.errors.reduce(
            (acc, curr) => {
              acc[curr.path[0] as keyof CreateTransferState] = curr.message
              return acc
            },
            {} as Partial<Record<keyof CreateTransferState, string>>,
          )
          setErrors(fieldErrors)
        }
        return false
      }
    },
    [state],
  )

  const handleNextStep = useCallback(() => {
    const currentIndex = steps.findIndex((step) => step.active)
    if (currentIndex < steps.length - 1) {
      if (validateStep(stepsConfig[currentIndex].path)) {
        navigate(stepsConfig[currentIndex + 1].path)
      }
    }
  }, [steps, navigate, validateStep])

  const goToPreviousStep = useCallback(() => {
    const currentIndex = steps.findIndex((step) => step.active)
    if (currentIndex > 0) {
      navigate(stepsConfig[currentIndex - 1].path)
    }
  }, [steps, navigate])

  const validateAllPreviousSteps = useCallback((): void => {
    const currentIndex = steps.findIndex((step) => step.active)
    for (let i = 0; i < currentIndex; i++) {
      if (!validateStep(stepsConfig[i].path)) {
        navigate(stepsConfig[0].path)
        return
      }
    }
  }, [validateStep, steps, navigate])

  const setSelectedTransferType = useCallback(
    (type: TransferType) => {
      setState((prevState) => ({ ...prevState, selectedTransferType: type }))
    },
    [setState],
  )

  useEffect(() => {
    setSteps((prevSteps) =>
      prevSteps.map((step, index) => ({
        ...step,
        active: location.pathname.includes(stepsConfig[index].path),
      })),
    )
  }, [location.pathname, setSteps])

  useEffect(() => {
    saveState(state)
  }, [state, saveState])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      sessionStorage.setItem("redirectToFirstStep", "true")
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [clearState])

  useEffect(() => {
    const redirectToFirstStep = sessionStorage.getItem("redirectToFirstStep")
    if (redirectToFirstStep === "true") {
      clearState()
      navigate(stepsConfig[0].path)
      sessionStorage.removeItem("redirectToFirstStep")
    }
  }, [clearState, navigate])

  return (
    <CreateTransferContext.Provider
      value={{
        state,
        setState,
        steps,
        setSteps,
        errors,
        setErrors,
        validateStep,
        handleNextStep,
        goToPreviousStep,
        validateAllPreviousSteps,
        setSelectedTransferType,
      }}
    >
      {children}
    </CreateTransferContext.Provider>
  )
}
