import { gql } from "@apollo/client"
import { GqlHeroError, ValidationGraphQLError } from "../../../../01_technical/requesting/request-error.type"

export const GET_MERCHANT = gql`
  query {
    getmerchant {
      id
      siret
      tvaNumber
      juridicalName
      websiteUrl
      tradingName
      publicEmail
      paymentNotificationEmail
      manualReviewNotificationEmail
      address {
        line1
        line2
        zipCode
        city
        region
        countryCode
      }
    }
    merchantLogo {
      url
    }
  }
`

type MerchantAddress = {
  city: string
  zipCode: string
  line1: string
  line2: string
  region: string
  countryCode: string
}

export type GetMerchantType = {
  getmerchant: {
    id: string
    siret: string
    tvaNumber: string
    juridicalName: string
    websiteUrl: string
    tradingName: string
    publicEmail: string
    paymentNotificationEmail?: string
    manualReviewNotificationEmail?: string
    address?: MerchantAddress
  }
  merchantLogo: {
    url: string
  }
}

export const GET_MERCHANT_LOGO_URL = gql`
  query {
    merchantLogo {
      url
    }
  }
`

export type GetMerchantLogoType = {
  merchantLogo: {
    url: string
  }
}

type UPDATE_MERCHANT_RESPONSE_SUCCESS = {
  siret: string | null
  VATNumber: string | null
  publicEmail: string
  tradingName: string
  juridicalName: string | null
  websiteUrl: string | null
  paymentNotificationEmail: string | null
  manualReviewNotificationEmail: string | null
  address: {
    line1: string
    line2: string
    city: string
    zipCode: string
    region: string
    countryCode: string
  }
}

export type UPDATE_MERCHANT_RESPONSE = {
  merchantUpdateSelf: UPDATE_MERCHANT_RESPONSE_SUCCESS | GqlHeroError | ValidationGraphQLError
}

export const UPDATE_MERCHANT = gql`
  mutation merchantUpdateSelf(
    $siret: String
    $VATNumber: String
    $juridicalName: String
    $websiteUrl: String
    $tradingName: String!
    $publicEmail: String!
    $paymentNotificationEmail: String
    $manualReviewNotificationEmail: String
    $line1: String!
    $line2: String!
    $zipCode: String!
    $city: String!
    $region: String!
    $countryCode: MerchantAddressCountryCode
  ) {
    merchantUpdateSelf(
      merchant: {
        siret: $siret
        VATNumber: $VATNumber
        juridicalName: $juridicalName
        tradingName: $tradingName
        publicEmail: $publicEmail
        paymentNotificationEmail: $paymentNotificationEmail
        manualReviewNotificationEmail: $manualReviewNotificationEmail
        websiteUrl: $websiteUrl
        address: {
          line1: $line1
          line2: $line2
          zipCode: $zipCode
          city: $city
          region: $region
          countryCode: $countryCode
        }
      }
    ) {
      ... on UpdateMerchantOutputSchema {
        siret
        VATNumber
        juridicalName
        websiteUrl
        tradingName
        publicEmail
        paymentNotificationEmail
        manualReviewNotificationEmail
        address {
          line1
          line2
          zipCode
          city
          region
          countryCode
        }
      }

      ... on GqlHeroError {
        errorCode
        message
        detail
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`

export type UpdateMerchantVariablesType = {
  siret: string | null
  VATNumber: string | null
  juridicalName: string | null
  websiteUrl: string | null
  tradingName: string
  publicEmail: string
  paymentNotificationEmail: string | null
  manualReviewNotificationEmail: string | null
  line1: string
  line2?: string
  zipCode: string
  city: string
  region: string
  countryCode?: string
}

export const GET_UPLOADABLE_MERCHANT_LOGO_SIGNED_URL = gql`
  query {
    uploadableMerchantLogoSignedUrl {
      signedUrl
    }
  }
`

export type GetUploadableMerchantLogoSignedUrlType = {
  uploadableMerchantLogoSignedUrl: { signedUrl: string }
}
