import { Checkbox, Typography } from "@hero/krypton"
import React from "react"
import { useDashboardTranslation } from "../../../../../../../01_technical/translations"
import { FilterSection } from "./FilterSection"

interface MethodFilterProps {
  selectedMethods: string[]
  onMethodChange: (method: string) => void
  hideHeader?: boolean
}

export const MethodFilter: React.FC<MethodFilterProps> = ({ selectedMethods, onMethodChange, hideHeader = false }) => {
  const { t } = useDashboardTranslation()

  const handleCheckboxChange = (method: string) => {
    onMethodChange(method)
  }

  return (
    <FilterSection>
      {!hideHeader && (
        <Typography $variant="body-4-semibold">{t("accounts.transaction.list.filters.chip.method")}</Typography>
      )}
      <Checkbox
        id="method-transfer"
        text={t("accounts.transaction.filter.transfer")}
        label={t("accounts.transaction.filter.transfer")}
        checked={selectedMethods.includes("transfer")}
        onChange={() => handleCheckboxChange("transfer")}
      />
      <Checkbox
        id="method-debit"
        text={t("accounts.transaction.filter.debit")}
        label={t("accounts.transaction.filter.debit")}
        checked={selectedMethods.includes("debit")}
        onChange={() => handleCheckboxChange("debit")}
      />
      <Checkbox
        id="method-card"
        text={t("accounts.transaction.filter.card")}
        label={t("accounts.transaction.filter.card")}
        checked={selectedMethods.includes("card")}
        onChange={() => handleCheckboxChange("card")}
      />
    </FilterSection>
  )
}
