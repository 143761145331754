import styled from "styled-components"
import CardPhysicalIcon from "../../00_shared/icons/card-physical-front.png"
import CardVirtualIcon from "../../00_shared/icons/card-virtual-front.png"
import { Typography } from "@hero/krypton"
import { IssuingCard } from "../../00_shared/business/IssuingCard"

const Content = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  gap: 8px;
`

const Illustration = styled.img`
  width: 40px;
`

const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface IssuingCardSmallVisualProps {
  card: IssuingCard
}

export const IssuingCardSmallVisual = ({ card }: IssuingCardSmallVisualProps) => {
  return (
    <Content>
      <Illustration
        src={card.issuanceType === "PHYSICAL" ? CardPhysicalIcon : CardVirtualIcon}
        style={card.status === "DEACTIVATED" ? { opacity: 0.3 } : {}}
      />
      <Information>
        <Typography $variant="caption-2">{card.displayLastCardDigits}</Typography>
      </Information>
    </Content>
  )
}
