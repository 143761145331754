import { createContext, useContext, useRef, useState } from "react"
import { Setup2faModal } from "./Setup2faModal"

interface Setup2faContextInterface {
  closeModal: () => void
  openModal: () => void
  timeRemaining: number
  setTimeRemaining: (timeRemaining: number) => void
  setOnAfterClose: (cb: () => void) => void
  setPhone: (phone: string) => void
}

const defaultFunction = () => {
  // should not happen
  console.error("ERROR_DEFAULT_CONTEXT_VALUE_USED")
}

const Setup2faContext = createContext<Setup2faContextInterface>({
  closeModal: defaultFunction,
  openModal: defaultFunction,
  timeRemaining: 0,
  setTimeRemaining: defaultFunction,
  setOnAfterClose: defaultFunction,
  setPhone: defaultFunction,
})

export const useSetup2faContext = () => useContext(Setup2faContext)

interface Setup2faProviderProps {
  children: JSX.Element
}

const Setup2faProvider = ({ children }: Setup2faProviderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [timeRemaining, setTimeRemaining] = useState(0)
  const [phone, setPhone] = useState("")

  function closeModal() {
    setIsModalOpen(false)
  }

  function openModal() {
    setIsModalOpen(true)
  }

  const _onAfterClose = useRef(defaultFunction)

  return (
    <Setup2faContext.Provider
      value={{
        closeModal,
        openModal,
        timeRemaining,
        setTimeRemaining,
        setOnAfterClose: (onAfterClose) => {
          _onAfterClose.current = onAfterClose
        },
        setPhone,
      }}
    >
      {children}
      <Setup2faModal
        isOpen={isModalOpen}
        onClose={() => {
          _onAfterClose.current()
          closeModal()
        }}
        phone={phone}
      />
    </Setup2faContext.Provider>
  )
}

export default Setup2faProvider
