import { gql } from "@apollo/client"
import { useMemo } from "react"
import { useHeroQuery } from "../../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"

const GET_MERCHANT_BUSINESS_ACCOUNTS_QUERY = gql`
  query GetMerchantBusinessAccounts {
    getMerchantBusinessAccounts {
      ... on GetMerchantBusinessAccountsOutput {
        businessAccounts {
          name
          balance
          ledgerId
          virtualIbanId
        }
      }

      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

type BusinessAccount = {
  name: string
  balance: number
  ledgerId: string
  virtualIbanId: string
  VIban: string
  BIC: string
}

export const useBusinessAccounts = () => {
  const { data, loading, error, refetch } = useHeroQuery<{ businessAccounts: BusinessAccount[] }>({
    gqlQuerySchema: GET_MERCHANT_BUSINESS_ACCOUNTS_QUERY,
    fetchPolicy: "cache-and-network",
  })

  const totalBalance = useMemo(
    () =>
      data && data.businessAccounts.length
        ? data.businessAccounts.reduce((acc, account) => acc + account.balance, 0)
        : 0,
    [data],
  )

  return {
    businessAccounts: data?.businessAccounts || [],
    totalBalance,
    loading,
    error,
    refetch,
  }
}
