import { Button, ErrorBlock, Field, toaster } from "@hero/krypton"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import { PinSchema } from "../../../../00_shared/utils/commonZodSchemas.utils"
import { CommonTFunction, useCommonTranslation } from "../../../../01_technical/translations"
import { use2faChallenge } from "../../../../Auth/Challenge2fa/use2faChallenge.hooks"
import { Panel } from "../component/Pannel"
import { UPDATE_2FA_PIN, UpdateArgs, UpdateResponse } from "./UpdateUserPin.requests"

const useUpdateUserPinForm = () => {
  const { t } = useCommonTranslation()
  const formSchema = getUpdateUserPinSchema(t)
  type SchemaType = z.infer<typeof formSchema>
  return useForm<SchemaType>({
    resolver: zodResolver(formSchema),
  })
}

const getUpdateUserPinSchema = (t: CommonTFunction) => {
  return z.object({
    pin: PinSchema(t),
  })
}

export const UpdateUserPinForm = () => {
  const { t } = useTranslation()
  const { t: commonT } = useCommonTranslation()

  const getTranslations = (t: ReturnType<typeof useCommonTranslation>["t"]) => ({
    PIN_EMPTY: commonT("auth.error.pinEmpty"),
    PIN_WEAK: commonT("auth.error.pinWeak"),
    PIN_WRONG_FORMAT: commonT("auth.error.pinWrongFormat"),
    PIN_REQUIRED: commonT("auth.error.pinEmpty"),
    "2FA_ABORTED": commonT("auth.login.error.2faAborted"),
    "2FA_FAILED": commonT("auth.login.error.2faFailed"),
  })

  const {
    mutationWith2fa: updatePin,
    mutationState: { error, loading },
  } = use2faChallenge<UpdateResponse, UpdateArgs>(UPDATE_2FA_PIN, getTranslations(commonT))

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useUpdateUserPinForm()

  const onSubmit = handleSubmit((data) => {
    updatePin({ pin: data.pin }).then(() => {
      toaster.success(t("merchant.tools.setting.resetPin.success"))
      reset()
    })
  })

  return (
    <form onSubmit={onSubmit}>
      <Panel>
        <Field
          type="password"
          fieldLabel={t("merchant.tools.setting.resetPin.pinField")}
          placeholder={t("merchant.tools.setting.resetPin.placeHolder")}
          {...register("pin")}
          aria-invalid={!!errors.pin}
          errorMessage={errors.pin?.message}
          inputMode="numeric"
        />

        <Button
          data-test-id="update-pin-button"
          disabled={loading || !isValid || isSubmitting}
          isLoading={loading}
          size="medium"
          style={{ alignSelf: "center" }}
          type="submit"
        >
          {t("merchant.tools.setting.resetPin.submit")}
        </Button>

        {error && <ErrorBlock>{error.translatedMessage}</ErrorBlock>}
      </Panel>
    </form>
  )
}
