import { OperationVariables, TypedDocumentNode } from "@apollo/client"
import { useState } from "react"
import { use2faChallenge } from "./use2faChallenge.hooks.DEPRECATED"

export const useMutationWith2fa = <RequestResponse, RequestArgs extends OperationVariables>(
  gqlSchema: TypedDocumentNode,
) => {
  const { mutationWith2fa } = use2faChallenge()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const mutationFnWith2fa = async (args: { variables: RequestArgs }) => {
    setError(null)
    setLoading(true)

    return mutationWith2fa(gqlSchema, args.variables)
      .then((res) => {
        setLoading(false)
        return {
          data: res.data as RequestResponse,
        }
      })
      .catch((e) => {
        setLoading(false)
        setError(anyToError(e))
        throw e
      })
  }

  return [
    mutationFnWith2fa,
    {
      loading,
      error: error,
    },
  ] as const
}

function anyToError(e: unknown): Error {
  if (e instanceof Error) {
    return e
  }

  if (typeof e === "string") {
    return new Error(e)
  }

  if (typeof e === "object" && e && "message" in e && typeof e.message === "string") {
    return new Error(e.message)
  }

  return new Error("Unknown error")
}
