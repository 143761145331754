import { FloatingBox as BaseFloatingBox, Chip } from "@hero/krypton"
import React, { MouseEvent } from "react"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../../../../01_technical/translations"
import { SearchParams, useTransactionFilters } from "../../../hooks/useTransactionFilters"
import { AmountFilter } from "./AmountFilter"
import { DateFilter } from "./DateFilter"
import { FilterContainer } from "./FilterContainer"
import { MethodFilter } from "./MethodFilter"
import { TypeFilter } from "./TypeFilter"

const ChipBox = styled.span`
  margin-right: 0.5rem;
`

const FloatingBox = styled(BaseFloatingBox)`
  z-index: 100;
`

type TransactionTableFiltersProps = {
  filterBusinessAccountOperations: (params: SearchParams) => void
}

export const TransactionTableFilters: React.FC<TransactionTableFiltersProps> = ({
  filterBusinessAccountOperations,
}) => {
  const { t } = useDashboardTranslation()
  const { amountState, dateState, methodState, typeState } = useTransactionFilters(filterBusinessAccountOperations, {
    amount: { applyImmediately: false },
    date: { applyImmediately: false },
    method: { applyImmediately: true },
    type: { applyImmediately: true },
  })

  const handleAnchors = (event: MouseEvent<HTMLElement>, anchorType: string) => {
    if (anchorType === "amount") {
      setAnchorAmountElement(anchorAmountElement ? undefined : event.currentTarget)
    } else if (anchorType === "date") {
      setAnchorDateElement(anchorDateElement ? undefined : event.currentTarget)
    } else if (anchorType === "type") {
      setAnchorTypeElement(anchorTypeElement ? undefined : event.currentTarget)
    } else if (anchorType === "method") {
      setAnchorMethodElement(anchorMethodElement ? undefined : event.currentTarget)
    }
  }

  const [anchorAmountElement, setAnchorAmountElement] = React.useState<HTMLElement | undefined>(undefined)
  const [anchorDateElement, setAnchorDateElement] = React.useState<HTMLElement | undefined>(undefined)
  const [anchorTypeElement, setAnchorTypeElement] = React.useState<HTMLElement | undefined>(undefined)
  const [anchorMethodElement, setAnchorMethodElement] = React.useState<HTMLElement | undefined>(undefined)

  const searchParams = new URLSearchParams(window.location.search)

  const amountUnder = searchParams.get("amountUnder")
  const amountEqual = searchParams.get("amountEqual")
  const amountOver = searchParams.get("amountOver")
  const dateFrom = searchParams.get("dateFrom")
  const dateTo = searchParams.get("dateTo")
  const transactionTypes = searchParams.get("transactionType")?.split(",") || []
  const methods = searchParams.getAll("method")

  return (
    <FilterContainer>
      {/* Amount Filter */}
      <ChipBox>
        <Chip
          label={t("accounts.transaction.list.filters.chip.amount")}
          onClear={() => amountState.reset()}
          onClick={(event: MouseEvent<HTMLElement>) => handleAnchors(event, "amount")}
          value={
            amountUnder
              ? `< ${Number(amountUnder) / 100}`
              : amountEqual
                ? `= ${Number(amountEqual) / 100}`
                : amountOver
                  ? `> ${Number(amountOver) / 100}`
                  : ""
          }
        />
      </ChipBox>
      <FloatingBox
        anchorElement={anchorAmountElement}
        onClose={() => setAnchorAmountElement(undefined)}
        isOpen={Boolean(anchorAmountElement)}
      >
        <AmountFilter
          amountUnder={amountState.amountUnder}
          amountEqual={amountState.amountEqual}
          amountOver={amountState.amountOver}
          setAmountUnder={amountState.setAmountUnder}
          setAmountEqual={amountState.setAmountEqual}
          setAmountOver={amountState.setAmountOver}
          onApply={amountState.apply}
          showApplyButton={true}
          hideHeader={true}
        />
      </FloatingBox>

      {/* Date Filter */}
      <ChipBox>
        <Chip
          label={t("accounts.transaction.list.filters.chip.date")}
          onClear={() => dateState.reset()}
          onClick={(event: MouseEvent<HTMLElement>) => handleAnchors(event, "date")}
          value={dateFrom && dateTo ? `Du ${dateFrom} au ${dateTo}` : ""}
        />
      </ChipBox>
      <FloatingBox
        anchorElement={anchorDateElement}
        onClose={() => setAnchorDateElement(undefined)}
        isOpen={Boolean(anchorDateElement)}
      >
        <DateFilter
          dateFrom={dateState.dateFrom}
          dateTo={dateState.dateTo}
          setDateFrom={dateState.setDateFrom}
          setDateTo={dateState.setDateTo}
          onApply={dateState.apply}
          hideHeader={true}
          autoApply={false}
          showApplyButton={true}
        />
      </FloatingBox>

      {/* Transaction Type Filter */}
      <ChipBox>
        <Chip
          label={t("transaction.list.filters.chip.type")}
          onClear={() => typeState.reset()}
          onClick={(event: MouseEvent<HTMLElement>) => handleAnchors(event, "type")}
          value={
            transactionTypes.length
              ? transactionTypes.map((type) => t(`accounts.transaction.filter.${type}`)).join(", ")
              : ""
          }
        />
      </ChipBox>
      <FloatingBox
        anchorElement={anchorTypeElement}
        onClose={() => setAnchorTypeElement(undefined)}
        isOpen={Boolean(anchorTypeElement)}
      >
        <TypeFilter selectedTypes={typeState.types} onTypeChange={typeState.toggleType} hideHeader={true} />
      </FloatingBox>

      {/* Method Filter */}
      <ChipBox>
        <Chip
          label={t("accounts.transaction.list.filters.chip.method")}
          onClear={() => methodState.reset()}
          onClick={(event: MouseEvent<HTMLElement>) => handleAnchors(event, "method")}
          value={methods.length ? methods.map((method) => t(`accounts.transaction.filter.${method}`)).join(", ") : ""}
        />
      </ChipBox>
      <FloatingBox
        anchorElement={anchorMethodElement}
        onClose={() => setAnchorMethodElement(undefined)}
        isOpen={Boolean(anchorMethodElement)}
      >
        <MethodFilter
          selectedMethods={methodState.methods}
          onMethodChange={methodState.toggleMethod}
          hideHeader={true}
        />
      </FloatingBox>
    </FilterContainer>
  )
}
