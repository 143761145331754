import { AddIcon, Button, DownloadIcon, FilterIcon } from "@hero/krypton"
import { useCallback, useMemo, useState } from "react"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { EmptyState } from "../../../../00_shared/components/EmptyState"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import useMediaQuery from "../../../../00_shared/hooks/useMediaQuery.hook"
import { useCommonTranslation } from "../../../../01_technical/translations"
import { UserLocale } from "../../../../business/enums/User.enum"
import { PaginationContainer } from "../../00_shared/components/PaginationContainer"
import { TableContainer } from "../../00_shared/components/TableContainer"
import { useBusinessAccountContext } from "../../businessAccount.context"
import { useMerchantOperations } from "../00_shared/hooks/useMerchantOperations"
import TransferIcon from "../00_shared/icons/transfer-icon.png"
import { formatMovementOperations } from "../00_shared/utils"
import { FiltersSheet } from "./components/transactions/TransactionFilters/FiltersSheet"
import { TransactionTableFilters } from "./components/transactions/TransactionFilters/TransactionTableFilters"
import { TransactionList } from "./components/transactions/TransactionList"
import TransactionTable from "./components/transactions/TransactionTable"
import { SearchParams, useTransactionFilters } from "./hooks/useTransactionFilters"
import { mapMethodToOperationTypes, mapTypeToOperationTypes } from "./utils"

const ButtonContainer = styled.div`
  position: absolute;
  top: 5.5rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
  button {
    min-width: unset;
  }
`

export const AccountTransactions = () => {
  const { isBaActive } = useBusinessAccountContext()
  const location = useLocation()
  const navigate = useNavigate()
  const { t, i18n } = useCommonTranslation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const { accountNb } = useParams<{ accountNb: string }>()
  const { operations, pagination, loading, setVariables } = useMerchantOperations()
  const { currentPage, totalPages } = pagination
  const isMobile = useMediaQuery("(max-width: 768px)")
  const [isFilterSheetOpen, setIsFilterSheetOpen] = useState(false)

  const isAllAccountView = !accountNb

  const formattedOperations = formatMovementOperations({
    operations,
    t,
    local: UserLocale[i18n.language as keyof typeof UserLocale],
  })

  const handleTopUpAccount = useCallback(() => {
    if (accountNb) {
      navigate(`/accounts/${accountNb}/top-up`)
    }
  }, [navigate, accountNb])

  const filterBusinessAccountOperations = useCallback(
    async (params: SearchParams) => {
      const methodOperationTypes = params.method?.map((method) => mapMethodToOperationTypes(method)).flat() || []
      const typeOperationTypes = params.transactionType?.map((type) => mapTypeToOperationTypes(type)).flat() || []

      setVariables({
        pagination: { page: params.page, limit: 10 },
        filters: {
          ...(accountNb && {
            productLedgerIds: [accountNb],
          }),
          operationTypes: [...methodOperationTypes, ...typeOperationTypes],
          amount: {
            equal: params.amountEqual,
            greaterThan: params.amountOver,
            lessThan: params.amountUnder,
          },
          date: {
            from: params.dateFrom ? new Date(params.dateFrom) : undefined,
            to: params.dateTo ? new Date(params.dateTo) : undefined,
          },
        },
      })
    },
    [accountNb, setVariables],
  )

  const { hasActiveFilters } = useTransactionFilters(filterBusinessAccountOperations, {
    amount: { applyImmediately: false },
    date: { applyImmediately: false },
    method: { applyImmediately: true },
    type: { applyImmediately: true },
  })

  const onPageChange = (pageNumber: number) => {
    searchParams.set("page", pageNumber.toString())
    navigate({ search: searchParams.toString() })
  }

  if (operations.length === 0 && !loading && !hasActiveFilters) {
    return (
      <EmptyState
        illustration={<img src={TransferIcon} alt="historique des opérations" width={100} />}
        width="22rem"
        title="Historique"
        text="Cet espace vous permet de consulter l'historique détaillé des opérations réalisées sur l'ensemble de vos comptes professionnels. Avec la possibilité de gérer plusieurs comptes, suivez et contrôlez facilement vos flux financiers à partir d’un seul et même endroit."
        buttonText="Alimenter son compte"
        buttonIcon={<AddIcon />}
        onButtonClick={() => handleTopUpAccount()}
        disabledButton={!isBaActive}
      />
    )
  }

  return (
    <>
      {!isMobile && (
        <TableContainer>
          <TransactionTableFilters filterBusinessAccountOperations={filterBusinessAccountOperations} />
          <TransactionTable operations={formattedOperations} isLoading={loading} isAllAccountView={isAllAccountView} />
          <PaginationContainer
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(pageNumber: number) => onPageChange(pageNumber)}
          />
          <Outlet />
        </TableContainer>
      )}
      {isMobile && (
        <>
          <ScrollArea height="">
            <TransactionList operations={formattedOperations} isLoading={loading} isAllAccountView={isAllAccountView} />
          </ScrollArea>
          <ButtonContainer>
            <Button size="small" isLoading={false} $variant="tertiary">
              <DownloadIcon />
            </Button>
            <Button size="small" isLoading={false} $variant="tertiary" onClick={() => setIsFilterSheetOpen(true)}>
              <FilterIcon />
            </Button>
          </ButtonContainer>
          <FiltersSheet
            isOpen={isFilterSheetOpen}
            setIsOpen={setIsFilterSheetOpen}
            onFiltersChange={filterBusinessAccountOperations}
          />
          <Outlet />
        </>
      )}
    </>
  )
}
