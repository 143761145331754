import { AddIcon, Button, LinkOutIcon, Spinner, Typography } from "@hero/krypton"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import useLocalStorage from "../../../00_shared/hooks/useLocalStorage.hook"
import { useDashboardTranslation } from "../../../01_technical/translations"
import OldCalculator from "../../../assets/collection/old_calc.png"
import { HERO_PRODUCT_ONBOARDING_URL } from "../../../env_variables"
import { EmptyScreen } from "../00_shared/components/EmptyScreen"
import { useProductContext } from "../ProductScopeContext"

const ActivateButton = styled(Button)`
  width: fit-content;
  min-width: unset;
  padding: 0.25rem;
`

export const HOCProtectActivatedPage = ({ children }: { children: React.ReactNode }) => {
  const productContext = useProductContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (productContext && !productContext.isAtLeastOneProductEnabled) {
      navigate("/collection/no-product-activated")
    }
  }, [productContext, navigate])

  if (!productContext || !productContext.isAtLeastOneProductEnabled) {
    return <Spinner />
  }

  return <>{children}</>
}

export const NoProductActivated = () => {
  const { t, i18n } = useDashboardTranslation()
  const [heroOnboardingState, setHeroOnboardingState] = useLocalStorage("heroOnboardingState", "not_started")
  const [loadingOnboarding, setLoadingOnboarding] = useState(false)
  const productContext = useProductContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (productContext && productContext.isAtLeastOneProductEnabled) {
      navigate("/collection/list?kind=all")
    }
  }, [productContext, navigate])

  return (
    <EmptyScreen
      imgSrc={OldCalculator}
      titlePageKey="collection.list.title"
      titleKey="collection.cockpit.noProductActivated.title"
      descriptionKey="collection.cockpit.noProductActivated.description"
      action={
        <ActivateButton
          leftIcon={heroOnboardingState === "started" ? LinkOutIcon : AddIcon}
          size="small"
          isLoading={loadingOnboarding}
          onClick={() => {
            if (loadingOnboarding) return

            setLoadingOnboarding(true)

            if (heroOnboardingState === "not_started") {
              setHeroOnboardingState("started")
            }

            setTimeout(() => {
              window.location.href = `${HERO_PRODUCT_ONBOARDING_URL}?flow=bnpl&country=${i18n.language.toUpperCase()}`
            }, 100)
          }}
        >
          <Typography $variant="body-4-semibold" $color="white">
            {heroOnboardingState === "not_started"
              ? t("collection.cockpit.noProductActivated.buttonActivate")
              : t("collection.cockpit.noProductActivated.buttonContinue")}
          </Typography>
        </ActivateButton>
      }
    />
  )
}
