import { AddIcon, Button, Separator, theme, Typography } from "@hero/krypton"
import { Link, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { ScrollArea } from "../../../../../../00_shared/components/ScrollArea"
import { useDashboardTranslation } from "../../../../../../01_technical/translations"
import { useBusinessAccounts } from "../../hooks/useBusinessAccounts"
import { currencyFormatter } from "../../utils"

const SidebarContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-right: 1px solid ${({ theme }) => theme.colors.grey.$300};
  height: 100%;
`

const SidebarHeader = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const AddAccountButton = styled(Button)`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  min-width: auto;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    background: none;
    border: none;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const AccountList = styled.ul`
  padding-right: 1px;
`

const AccountItem = styled.li<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  cursor: pointer;
  background: ${({ $isSelected, theme }) => ($isSelected ? theme.colors.grey.$100 : "none")};
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.grey.$100};
  }
`

const AllAccountsItem = styled(AccountItem)`
  background-color: ${({ theme }) => theme.colors.grey.$100};
  padding: 1rem;
`

const AccountName = styled(Typography).attrs(() => ({
  $variant: "body-4-medium",
}))`
  margin: 0;
`

const AccountBalance = styled(Typography).attrs(() => ({
  $variant: "body-4-regular",
}))`
  margin: 0;
`

export const AccountSidebar = () => {
  const { t } = useDashboardTranslation()
  const navigate = useNavigate()
  const { businessAccounts, totalBalance } = useBusinessAccounts()
  const { accountNb } = useParams<{ accountNb: string }>()

  const handleSelectAccount = (ledgerId?: string) => {
    if (!ledgerId) {
      navigate(`/accounts/transactions`)
      return
    }
    navigate(`/accounts/${ledgerId}/transactions`)
  }

  return (
    <SidebarContainer>
      <SidebarHeader>
        <Typography $variant="title-3-semibold">{t("accounts.sidebar.header.title")}</Typography>
        <AddAccountButton as={Link} to="/accounts/create">
          <AddIcon title="Add Account" color={theme.colors.black.$600} />
        </AddAccountButton>
      </SidebarHeader>
      <AllAccountsItem $isSelected={true} onClick={() => handleSelectAccount()}>
        <AccountName>{t("accounts.sidebar.allAccounts.text")}</AccountName>
        <AccountBalance>{currencyFormatter(totalBalance)}</AccountBalance>
      </AllAccountsItem>
      <Separator />
      <ScrollArea height="calc(100vh - 9.5rem)" width="15rem">
        <AccountList>
          {businessAccounts.map((account, index) => (
            <AccountItem
              key={index}
              $isSelected={account.ledgerId === accountNb}
              onClick={handleSelectAccount.bind(null, account.ledgerId)}
            >
              <AccountName>{account.name}</AccountName>
              <AccountBalance>{currencyFormatter(account.balance)}</AccountBalance>
            </AccountItem>
          ))}
        </AccountList>
      </ScrollArea>
    </SidebarContainer>
  )
}
