import { AddIcon, Button, Typography } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"

const NewLinkButtonStyled = styled(Button)`
  width: fit-content;
  min-width: unset;
  padding: 0.25rem;
`

export const NewLinkButton = () => {
  const { t } = useDashboardTranslation()
  const navigate = useNavigate()

  return (
    <NewLinkButtonStyled
      leftIcon={AddIcon}
      size="small"
      onClick={() => {
        navigate("/collection/new-link")
      }}
    >
      <Typography $variant="body-4-semibold" $color="white">
        {t("collection.list.newLink")}
      </Typography>
    </NewLinkButtonStyled>
  )
}
