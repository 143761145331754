import { Button } from "@hero/krypton"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../../../00_shared/components/Card"
import { Dialog, DialogContent } from "../../../../00_shared/components/Dialog"
import useMediaQuery from "../../../../00_shared/hooks/useMediaQuery.hook"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { AccountInformation } from "../00_shared/components/AccountInformation"
import { BusinessAccount, MerchantInfo, useBusinessAccount } from "../00_shared/hooks/useBusinessAccount"

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    gap: 1rem;
  }
`

const BankInformationContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const CardContainer = styled(Card)`
  @media (max-width: 768px) {
    padding: 0;
    border: none !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const CardContentContainer = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const CardFooterContainer = styled(CardFooter)`
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`

interface BankDetailsProps {
  isMobile?: boolean
  businessAccount?: BusinessAccount
  merchantInfo?: MerchantInfo
  title: string
  buttonDownload: string
  holder: string
}

const BankDetails = (props: BankDetailsProps) => {
  return (
    <CardContainer>
      <FlexContainer>
        <CardHeader>
          <CardTitle size={props.isMobile ? "large" : "medium"}>{props.title}</CardTitle>
        </CardHeader>
        <CardContentContainer>
          <BankInformationContainer>
            <AccountInformation label="IBAN" value={props.businessAccount?.VIban || ""} canCopy={true} />
            <AccountInformation label="BIC/SWIFT" value={props.businessAccount?.BIC || ""} canCopy={true} />
          </BankInformationContainer>
          <AccountInformation
            label={props.holder}
            value={
              <dl>
                <dt>
                  {props.merchantInfo?.owner?.firstname} {props.merchantInfo?.owner?.lastname}
                </dt>
                <dt>{props.merchantInfo?.address?.line1}</dt>
                <dt>{props.merchantInfo?.address?.line2}</dt>
                <dt>
                  {props.merchantInfo?.address?.zipCode} {props.merchantInfo?.address?.city}
                </dt>
              </dl>
            }
          />
        </CardContentContainer>
      </FlexContainer>
      <CardFooterContainer>
        <Button size="medium" isLoading={false}>
          {props.buttonDownload}
        </Button>
      </CardFooterContainer>
    </CardContainer>
  )
}

export const AccountDetailsBankDetails = () => {
  const { t } = useDashboardTranslation()
  const { accountNb } = useParams<{ accountNb: string }>()
  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width: 768px)")
  const { businessAccount, merchantInfo } = useBusinessAccount(accountNb)

  const preventAutoFocus = (event: Event) => {
    event.preventDefault()
  }

  const handleCloseOverlay = () => {
    navigate("..")
  }

  return (
    <>
      {!isMobile && (
        <BankDetails
          businessAccount={businessAccount}
          merchantInfo={merchantInfo}
          title={t("accounts.details.bankDetails.title")}
          holder={t("accounts.details.bankDetails.accountInformation.holder")}
          buttonDownload={t("accounts.details.bankDetails.buttonDownload")}
        />
      )}
      {isMobile && (
        <Dialog open={true} onOpenChange={handleCloseOverlay}>
          <DialogContent onOpenAutoFocus={preventAutoFocus} $disableAnimations={true} width="100%" height="100%">
            <BankDetails
              isMobile={isMobile}
              businessAccount={businessAccount}
              merchantInfo={merchantInfo}
              title={t("accounts.details.bankDetails.title")}
              holder={t("accounts.details.bankDetails.accountInformation.holder")}
              buttonDownload={t("accounts.details.bankDetails.buttonDownload")}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
