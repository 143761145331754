/* eslint-disable i18next/no-literal-string */
import { ErrorBlock, Field, FieldSelect, Typography } from "@hero/krypton"
import { useEffect } from "react"
import { Controller } from "react-hook-form"
import styled from "styled-components"
import CenteredLoading, { CenteredBasicError } from "../../../../00_shared/components/CenteredLoading"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useGetInstallments } from "../requests/useGetFinancingInstallments"
import { useCwtApplicationFormContext } from "./ApplicationForm.utils"
import { useGetAvailablePaymentOptions } from "./useGetAvailablePaymentOptions.request"

const Spacer = styled.div<{ $h: string }>`
  height: ${({ $h }) => $h};
`

const FinancingDetailsContainer = styled.div<{ $loading: boolean }>`
  padding: 1.25rem;
  background-color: ${({ theme }) => theme.colors.grey.$100};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  margin-top: 1rem;
  position: relative;

  ${({ $loading }) =>
    $loading &&
    `
    & > * {
      opacity: 0;
    }

    & .loader {
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  `}
`

const FinancingDetailsRow = styled.div`
  margin: 0.5rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const InstallmentAmount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const InstallmentFee = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
`

export const FinancingFormSection = () => {
  const { t } = useDashboardTranslation()
  const { register, control, formState, setValue } = useCwtApplicationFormContext()

  const { data, error, loading } = useGetAvailablePaymentOptions()

  useEffect(() => {
    if (data?.numberOfInstallmentsAllowed.length) {
      setValue("installmentsCount", data.numberOfInstallmentsAllowed[0])
    }
  }, [data, setValue])

  return (
    <>
      <Typography $variant="title-2-bold">{t("creditWireTransfer.applicationForm.financingSectionTitle")}</Typography>

      <Spacer $h={"1rem"} />

      <Field
        $fullWidth
        fieldLabel={t("creditWireTransfer.applicationForm.debtorIBAN")}
        type="text"
        {...register("debtorIBAN")}
        errorMessage={formState.errors?.debtorIBAN?.message}
      />

      <div
        style={{
          position: "relative",
        }}
      >
        {(loading || error) && (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "#ffffffbf",
              zIndex: 1,
              borderRadius: "0.5rem",
            }}
          >
            {loading && <CenteredLoading />}
            {error && <CenteredBasicError />}
          </div>
        )}
        <Controller
          control={control}
          name="installmentsCount"
          render={({ field: { value, onBlur, onChange } }) => (
            <FieldSelect
              value={value}
              $fullWidth
              noErrorMessage
              fieldLabel={t("creditWireTransfer.applicationForm.installmentCount")}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(parseInt(e.target.value, 10))}
              onBlur={onBlur}
              disabled={loading || error}
            >
              {data?.numberOfInstallmentsAllowed.map((installmentsCount) => (
                <option key={installmentsCount} value={installmentsCount}>
                  {installmentsCount}x
                </option>
              ))}
            </FieldSelect>
          )}
        />
      </div>

      <FinancingDetails />
    </>
  )
}

const FinancingDetails = () => {
  const { t } = useDashboardTranslation()
  const { watch } = useCwtApplicationFormContext()
  const installmentsCount = watch("installmentsCount")
  const amount = watch("amount")

  const { data, loading, error } = useGetInstallments({
    amount: Number(amount) * 100,
    installmentsCount: Number(installmentsCount),
  })

  if (Boolean(Number(amount)) && error !== null) {
    console.error(error)
    return <ErrorBlock $margin="1rem 0 0 0">{error?.translatedMessage}</ErrorBlock>
  }

  const installments: {
    position: number
    totalAmount: number
    feeAmount: number
  }[] = loading
    ? Array(Number(installmentsCount)).fill({ position: -1, totalAmount: 0, feeAmount: 0 })
    : data?.installments || []

  // should not happen
  if (installments.length === 0) {
    return null
  }

  return (
    <FinancingDetailsContainer $loading={loading}>
      <Typography $variant="body-3-semibold">{t("creditWireTransfer.applicationForm.financingPlan")}</Typography>

      <Typography $variant="caption-2">{installmentsCount}x</Typography>

      {[...installments].sort(sortByPosition).map((installment, i) => (
        <FinancingDetailsRow key={amount + "-" + i + "-" + installment.position}>
          <Typography>
            {installment.position === 0 && t("creditWireTransfer.applicationForm.firstInstallment")}
            {installment.position !== 0 &&
              t("creditWireTransfer.applicationForm.installment", { count: installment.position })}
          </Typography>

          <InstallmentAmount>
            <Typography>{toEuros(installment.totalAmount)}</Typography>
            <InstallmentFee $variant="caption-2">
              {t("creditWireTransfer.applicationForm.installmentFee", { euros: toEuros(installment.feeAmount) })}
            </InstallmentFee>
          </InstallmentAmount>
        </FinancingDetailsRow>
      ))}

      {loading && (
        <div className="loader">
          <CenteredLoading />
        </div>
      )}
    </FinancingDetailsContainer>
  )
}

const sortByPosition = (a: { position: number }, b: { position: number }) => a.position - b.position
