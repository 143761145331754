export enum OperationType {
  SEPA_CREDIT_TRANSFER_IN = "SEPA_CREDIT_TRANSFER_IN",
  SEPA_CREDIT_TRANSFER_OUT = "SEPA_CREDIT_TRANSFER_OUT",
  CARD_REFUND = "CARD_REFUND",
  CARD_PAYMENT = "CARD_PAYMENT",
  ACCOUNT_TO_ACCOUNT_IN = "ACCOUNT_TO_ACCOUNT_IN",
  ACCOUNT_TO_ACCOUNT_OUT = "ACCOUNT_TO_ACCOUNT_OUT",
}

export enum LedgerRegistryType {
  // AP = "AP",
  // CHECKOUT = "CHECKOUT",
  // LINK = "LINK",
  // CASH = "CASH",
  // RESERVE = "RESERVE",
  // CASH_HOLD = "CASH_HOLD",
  // CHECKOUT_HOLD = "CHECKOUT_HOLD",
  // LINK_HOLD = "LINK_HOLD",
  BUSINESS_ACCOUNT = "BUSINESS_ACCOUNT",
}

export interface MerchantOperation {
  id: string
  ledgerId: string
  ledgerType: LedgerRegistryType
  amount: number
  creditorId: string
  creditorName?: string
  debtorId?: string
  debtorName?: string
  reference?: string
  operationType: OperationType
  createdAt: string
  isExternalTransfer: boolean
}
