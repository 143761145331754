import { Header, Typography } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { TransfersHeaderTabs } from "./TransfersHeaderTabs"

const HeaderContainer = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: start;
  overflow-x: auto;

  @media (max-width: 768px) {
    padding: 1rem 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    min-height: 0;

    &::before {
      background: none;
      width: unset;
      content: none;
    }
  }

  &::before {
    background: none;
    width: unset;
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TransfersHeader = () => {
  const { t } = useDashboardTranslation()

  return (
    <HeaderContainer>
      <TitleContainer>
        <Typography $variant="title-3-semibold">{t("transfers.header.title")}</Typography>
        <TransfersHeaderTabs />
      </TitleContainer>
    </HeaderContainer>
  )
}
