import { Modal, Typography } from "@hero/krypton"
import { useDashboardTranslation } from "../../01_technical/translations"
import accessDeniedImage from "../../assets/access_denied.svg"

import styled from "styled-components"
import { SupportContact } from "../../00_shared/components/SupportContact"

const Title = styled(Typography)`
  margin-bottom: 0.25rem;
`

const ImageWrapper = styled.div`
  height: 6.25rem;
  width: 6.25rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  padding: 18px;
`

interface Check2faSetupModalProps {
  isOpen: boolean
  onClose: () => void
  phone: string
}

export const Setup2faModal = ({ isOpen, onClose, phone }: Check2faSetupModalProps) => {
  const { t } = useDashboardTranslation()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Wrapper>
        <ImageWrapper>{<img src={accessDeniedImage} alt="" />}</ImageWrapper>
        <Title $variant="title-3-semibold">{t("auth.2fa.enrollment.modal.title")}</Title>
        {phone && <Title $variant="title-3-inter-bold">{phone}</Title>}
        <br />
        <Typography>{t("auth.2fa.enrollment.modal.description")}</Typography>
        <>
          <br />
          <Typography $variant="caption-1">{t("auth.2fa.enrollment.modal.redirect")}</Typography>
        </>
        <SupportContact />
      </Wrapper>
    </Modal>
  )
}
