import { createContext, useContext, useRef, useState } from "react"
import { Challenge2faModal } from "./Challenge2faModal"

interface Challenge2faContextInterface {
  closeModal: () => void
  openModal: () => void
  timeRemaining: number
  setTimeRemaining: (time: number) => void
  setOnAfterClose: (cb: () => void) => void
  setPhone: (phone: string) => void
}

const defaultFunction = () => {
  // should not happen
  console.error("ERROR_DEFAULT_CONTEXT_VALUE_USED")
}

const Challenge2faContext = createContext<Challenge2faContextInterface>({
  closeModal: defaultFunction,
  openModal: defaultFunction,
  timeRemaining: 0,
  setTimeRemaining: defaultFunction,
  setOnAfterClose: defaultFunction,
  setPhone: defaultFunction,
})

export const useChallenge2faContext = () => useContext(Challenge2faContext)

interface Challenge2faProviderProps {
  children: JSX.Element
}

const Challenge2faProvider = ({ children }: Challenge2faProviderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [timeRemaining, setTimeRemaining] = useState(0)
  const [phone, setPhone] = useState("")

  function closeModal() {
    setIsModalOpen(false)
  }

  function openModal() {
    setIsModalOpen(true)
  }

  const _onAfterClose = useRef(defaultFunction)

  return (
    <Challenge2faContext.Provider
      value={{
        closeModal,
        openModal,
        timeRemaining,
        setTimeRemaining,
        setPhone,
        setOnAfterClose: (onAfterClose) => {
          _onAfterClose.current = onAfterClose
        },
      }}
    >
      {children}
      <Challenge2faModal
        is2faModalOpen={isModalOpen}
        close2faModal={() => {
          _onAfterClose.current()
          closeModal()
        }}
        phone={phone}
      />
    </Challenge2faContext.Provider>
  )
}

export default Challenge2faProvider
