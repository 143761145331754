import Intercom, { boot, shutdown } from "@intercom/messenger-js-sdk"
import { createContext, useContext, useEffect } from "react"
import { useAuthContext } from "./Auth/auth.context"
import { INTERCOM_APP_ID, INTERCOM_ENABLED } from "./env_variables"

interface IntercomContextInterface {
  bootIntercom: () => void
}

const defaultFunction = () => {
  // should not happen
  console.error("ERROR_DEFAULT_CONTEXT_VALUE_USED")
}

const IntercomContext = createContext<IntercomContextInterface>({
  bootIntercom: defaultFunction,
})

export const useIntercomContext = () => useContext(IntercomContext)

interface IntercomProviderProps {
  children: JSX.Element | JSX.Element[]
}

const IntercomProvider = ({ children }: IntercomProviderProps) => {
  const { currentUser } = useAuthContext()

  useEffect(() => {
    if (currentUser && INTERCOM_ENABLED) {
      shutdown()
      boot({
        app_id: INTERCOM_APP_ID,
        region: "eu",
        user_id: currentUser.id,
        name: currentUser.merchantTradingName,
        email: currentUser.email,
        user_hash: currentUser.intercomUserHash,
        boot_on_load: true,
        autoBoot: true,
      })
    }
    if (!currentUser) {
      bootIntercom()
    }
  }, [currentUser])

  const bootIntercom = () => {
    if (INTERCOM_ENABLED) {
      Intercom({
        app_id: INTERCOM_APP_ID,
        region: "eu",
        boot_on_load: true,
        autoBoot: true,
      })
    }
  }

  return <IntercomContext.Provider value={{ bootIntercom }}>{children}</IntercomContext.Provider>
}

export default IntercomProvider
