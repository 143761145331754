import { Field, Typography } from "@hero/krypton"
import { Controller } from "react-hook-form"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useCwtApplicationFormContext } from "./ApplicationForm.utils"

const Spacer = styled.div<{ $h: string }>`
  height: ${({ $h }) => $h};
`

const FieldRow = styled.div`
  display: flex;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }
`

export const AmountFormSection = () => {
  const { t } = useDashboardTranslation()
  const { register, control, formState } = useCwtApplicationFormContext()

  return (
    <>
      <Typography $variant="title-2-bold">{t("creditWireTransfer.applicationForm.amountToFinance")}</Typography>

      <Spacer $h={"1rem"} />

      <FieldRow>
        <Controller
          control={control}
          name="amount"
          render={({ field: { onChange, onBlur, value }, fieldState }) => {
            return (
              <Field
                $fullWidth
                fieldLabel={t("creditWireTransfer.applicationForm.amount")}
                type="number"
                errorMessage={fieldState.error?.message}
                onBlur={onBlur}
                value={String(value).trim()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value.trim().length === 0 ? "0" : e.target.value
                  const parsedValue = parseInt(value)

                  if (
                    !isNaN(parsedValue) && //
                    parsedValue >= 0 &&
                    parsedValue <= 1_000_000
                  ) {
                    const amount = parsedValue
                    onChange(amount)
                  }
                }}
              />
            )
          }}
        />

        <Field
          $fullWidth
          fieldLabel={t("creditWireTransfer.applicationForm.wireLabel")}
          type="text"
          {...register("label")}
          errorMessage={formState.errors?.label?.message}
        />
      </FieldRow>

      <Typography $variant="caption-2">{t("creditWireTransfer.applicationForm.financingDisclaimer")}</Typography>

      <Spacer $h={"1rem"} />

      <Typography $variant="caption-2">{t("creditWireTransfer.applicationForm.financingDisclaimer2")}</Typography>

      <Spacer $h={"1.5rem"} />
    </>
  )
}
