import { gql } from "@apollo/client"
import { useDebounce } from "../../../../00_shared/hooks/useDebounce.hooks"
import { useHeroQuery } from "../../../../01_technical/requesting/useHeroQuery/useHeroQuery"

export const useGetInstallments = (variables: { amount: number; installmentsCount: number }) => {
  const [delayedAmount, isDebouncing] = useDebounce(variables.amount, 500)

  const result = useHeroQuery<GET_INSTALLMENTS_SUCCESS>({
    gqlQuerySchema: GET_INSTALLMENTS,
    fetchPolicy: "cache-first",
    variables: {
      amount: delayedAmount,
      installmentsCount: variables.installmentsCount,
    },
    skip: !delayedAmount,
  })

  return {
    ...result,
    error: isDebouncing ? null : result.error,
    loading: result.loading || isDebouncing,
  }
}

const GET_INSTALLMENTS = gql`
  query getCwtInstallments($amount: Int!, $installmentsCount: Int!) {
    getCwtInstallments(amount: $amount, installmentsCount: $installmentsCount) {
      ... on GetInstallmentsOutput {
        success
        installments {
          position
          totalAmount
          feeAmount
        }
      }

      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

type GET_INSTALLMENTS_SUCCESS = {
  success: boolean
  installments: {
    position: number
    totalAmount: number
    feeAmount: number
  }[]
}
