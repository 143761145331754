import { DateTime } from "luxon"

export const toFrenchDate = (d: string) => DateTime.fromISO(d).setLocale("fr").toLocaleString()

export enum DateStyle {
  NUMERIC,
  TEXTUAL,
}

export const toInternationalDate = ({
  date,
  language,
  style = DateStyle.NUMERIC,
}: {
  date: string | Date
  language: string
  style?: DateStyle
}) => {
  const locale = getEuropeanLocale(language)

  const LuxonDateTime = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date)
  const options = style === DateStyle.NUMERIC ? DateTime.DATE_SHORT : DateTime.DATE_MED

  return LuxonDateTime.setLocale(locale).toLocaleString(options)
}

const getEuropeanLocale = (language: string) => {
  if (language === "en") {
    return "en-UK"
  }

  if (language === "es") {
    return "es-ES"
  }

  if (language === "de") {
    return "de-DE"
  }

  if (language === "fr") {
    return "fr-FR"
  }

  if (language === "it") {
    return "it-IT"
  }

  return language
}
