import styled, { css } from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin: 1.5rem 0;
  flex: 1;
`

export const Label = styled.span`
  ${({ theme }) => css`
    font-family: Inter;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.colors.grey.$600};
    font-size: 14px;
  `}
`
