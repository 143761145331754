import { gql } from "@apollo/client"

export type UpdateArgs = {
  pin: string
}

export type UpdateResponse = {
  success: boolean
}
export const UPDATE_2FA_PIN = gql`
  mutation update2FAPin($pin: String!) {
    update2FAPin(pin: $pin) {
      ... on Update2FAPinOutput {
        success
      }
      ... on SimpleApiError {
        errorCode
      }
    }
  }
`
