import { AddIcon } from "@hero/krypton"
import { useMemo } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { EmptyState } from "../../../../00_shared/components/EmptyState"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import useMediaQuery from "../../../../00_shared/hooks/useMediaQuery.hook"
import BeneficiariesIcon from "../00_shared/icons/beneficiaries-icon.png"
import { BeneficiariesList } from "./components/BeneficiariesList"
import { BeneficiariesTable } from "./components/BeneficiariesTable"
import { useBeneficiaries } from "./hooks/useBeneficiaries"

export const Beneficiaries = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const isMobile = useMediaQuery("(max-width: 768px)")
  const { beneficiaries, pagination, loading } = useBeneficiaries()

  const onPageChange = (pageNumber: number) => {
    searchParams.set("page", pageNumber.toString())
    navigate({ search: searchParams.toString() })
  }

  const handleCreateBeneficiary = () => {
    navigate("/transfers/beneficiaries/create")
  }

  if (beneficiaries.length === 0 && !loading) {
    return (
      <EmptyState
        width="22rem"
        illustration={<img src={BeneficiariesIcon} alt="beneficiary icon" width={100} />}
        title="Gestion des Bénéficiaires"
        text="Organisez et gérez facilement les destinataires de vos transactions financières dans cet onglet. Ajoutez de nouveaux bénéficiaires, modifiez leurs informations ou accédez rapidement à vos contacts fréquents pour un envoi d'argent efficace et sans tracas."
        buttonText="Ajouter un bénéficiaire"
        buttonIcon={<AddIcon />}
        onButtonClick={() => handleCreateBeneficiary()}
      />
    )
  }

  return (
    <>
      {!isMobile && (
        <BeneficiariesTable
          beneficiaries={beneficiaries}
          pagination={pagination}
          isLoading={loading}
          onPageChange={onPageChange}
        />
      )}
      {isMobile && (
        <>
          <ScrollArea height="">
            <BeneficiariesList beneficiaries={beneficiaries} isLoading={loading} />
          </ScrollArea>
          <Outlet />
        </>
      )}
    </>
  )
}
