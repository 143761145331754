import { Navigate } from "react-router-dom"
import { TransfersRoot } from "../transfers/00_shared/components/TransfersRoot"
import { AddBeneficiary } from "../transfers/AddBeneficiary/AddBeneficiary.screen"
import { BeneficiariesRoot } from "../transfers/Beneficiaries/Beneficiaries.root"
import { Beneficiaries } from "../transfers/Beneficiaries/Beneficiaries.screen"
import { CreateTransfer } from "../transfers/CreateTransfer/CreateTransfer.screen"
import { TransferHistoryRoot } from "../transfers/TransferHistory/TransferHistory.root"
import { TransferHistory } from "../transfers/TransferHistory/TransferHistory.screen"
import { TransferHistoryTransactionInformations } from "../transfers/TransferHistory/TransferHistoryTransactionInformations"
import { createTransferRoutes } from "./create-transfer.routes"

export const transfersRoutes = {
  path: "transfers",
  children: [
    {
      element: <TransfersRoot />,
      children: [
        {
          index: true,
          element: <Navigate to="transactions" />,
        },
        {
          path: "transactions",
          element: <TransferHistoryRoot />,
          children: [
            {
              path: "",
              element: <TransferHistory />,
              children: [{ path: ":transactionId", element: <TransferHistoryTransactionInformations /> }],
            },
          ],
        },
        {
          path: "beneficiaries",
          element: <BeneficiariesRoot />,
          children: [
            { path: "", element: <Beneficiaries /> },
            { path: "create", element: <AddBeneficiary /> },
          ],
        },
        {
          path: "create",
          element: <CreateTransfer />,
          children: createTransferRoutes,
        },
      ],
    },
  ],
}
