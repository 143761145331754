import React, { FC, useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { Separator, Typography, Modal, Field, InfoIcon, Tooltip, Button, toaster } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { currencyFormatter, centsToEuros, eurosToCents } from "../../../../00_shared/utils/currency.converter"
import { Payment, useRefundPaymentLink } from "../../List/List.requests"
import { PaymentType } from "../enums/PaymentCore.enum"

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`

const Content = styled.main`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const Divider = styled(Separator)`
  height: 1px;
`

const Description = styled(Typography)`
  color: ${(props) => props.theme.colors.grey.$500};
`

const SectionTitle = styled(Typography)`
  color: ${(props) => props.theme.colors.grey.$500};
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
`

const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`

const StyledField = styled(Field)`
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

export const RefundModal: FC<{
  payment?: Payment
  isOpen: boolean
  onClose: () => void
}> = ({ payment, isOpen, onClose }) => {
  const { t } = useDashboardTranslation()
  const { register, getValues } = useForm<{ amount: string }>()
  const [refundPaymentLink, { loading, error }] = useRefundPaymentLink()

  useEffect(() => {
    if (error) {
      toaster.error(error.message)
    }
  }, [error])

  const handleSubmit = useCallback(async () => {
    if (!payment) {
      return
    }

    const { amount } = getValues()

    try {
      await refundPaymentLink({
        variables: {
          input: {
            amount: eurosToCents(Number(amount)),
            paymentLinkId: payment.id,
          },
        },
      })

      toaster.success(t("collection.modal.refund.success"))
    } catch (error) {
      console.error({ error })
    }
  }, [getValues, payment, refundPaymentLink, t])

  if (!payment) {
    return null
  }

  const paymentTypeLabel =
    payment.paymentType === PaymentType.PAY_1X
      ? t("collection.list.types.1x")
      : payment.paymentType === PaymentType.PAY_ND
        ? t("collection.list.types.nd", { days: payment.daysBeforeDueDate })
        : t("collection.list.types.nx", { installmentCount: payment.installmentCount })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Content>
        <Header>
          <Typography $variant="title-1-bold">{t("collection.modal.refund.title")}</Typography>
          <Description $variant="body-4-regular">{t("collection.modal.refund.subtitle")}</Description>
        </Header>
        <Section>
          <SectionTitle $variant="body-4-regular">{t("collection.modal.refund.amount")}</SectionTitle>
          <Typography $variant="body-4-medium">{currencyFormatter(centsToEuros(payment.amount))}</Typography>
        </Section>
        <Divider />
        <Section>
          <SectionTitle $variant="body-4-regular">{t("collection.modal.refund.type")}</SectionTitle>
          <Typography $variant="caption-1-bold">{paymentTypeLabel ?? "-"}</Typography>
        </Section>
        <Divider />
        <Section>
          <SectionTitle $variant="body-4-regular">{t("collection.modal.refund.customer")}</SectionTitle>
          <Typography $variant="body-4-medium">{payment.customerEmail}</Typography>
        </Section>
        <Divider />

        <FormHeader>
          <Typography $variant="body-4-medium">{t("collection.modal.refund.field")}</Typography>
          <Tooltip content={t("collection.modal.refund.tooltip")} id={`refund-${payment.id}`} position="top-left">
            <InfoIcon />
          </Tooltip>
        </FormHeader>

        <StyledField
          type="number"
          placeholder={centsToEuros(payment.amount).toString()}
          step="1"
          aria-invalid
          {...register("amount")}
        />

        <ButtonContainer>
          <Button $variant="primary" onClick={handleSubmit} isLoading={loading} disabled={loading}>
            {t("collection.modal.refund.field")}
          </Button>
        </ButtonContainer>
      </Content>
    </Modal>
  )
}
