import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

const CREATE_BENEFICIARY = gql`
  mutation createBeneficiary($input: CreateBeneficiaryInput!) {
    createBeneficiary(input: $input) {
      ... on CreateBeneficiaryOutput {
        success
        beneficiaryId
      }
      ... on SimpleApiError {
        errorCode
      }

      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`

type Beneficiary = {
  fullName: string
  iban: string
  bic: string
  label: string
  bankName?: string
}

type CreateBeneficiaryOutput = {
  success: boolean
  beneficiaryId: string
}

type CreateBeneficiaryInput = {
  input: Beneficiary
}

export const useAddBeneficiary = () => {
  const [mutate, { data, loading, error }] = useHeroMutation<CreateBeneficiaryOutput, CreateBeneficiaryInput>({
    gqlQuerySchema: CREATE_BENEFICIARY,
  })

  return { mutate, loading, error, data }
}
