import { toaster } from "@hero/krypton"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { IssuingCardUsage } from "../00_shared/components/IssuingCardUsage"
import { IssuingCardWithActions } from "../00_shared/components/IssuingCardWithActions"
import { LostCardModal } from "../00_shared/components/LostCardModal"
import { IssuingCardTransactionsTable } from "./components/IssuingCardTransactionsTable"
import { useIssuingCardDetailsHook } from "./IssuingCardDetails.hook"

const Card = styled.div`
  overflow-y: auto;
  min-height: 0;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
`

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px 16px 0 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f8f8f8 100%);
  padding: 2rem;
  border-bottom: 1px solid #e9eaf0;
`

const CardContent = styled.div`
  padding: 2rem;
`

const Container = styled.div`
  display: grid;
  min-height: 0;
  min-width: 0;
  grid-template-columns: 25rem minmax(0, 1fr);
  gap: 1.5rem;
  overflow-y: scroll:
`

export const IssuingCardDetails = () => {
  const navigate = useNavigate()
  const { cardId } = useParams()
  const { card, transactions, loading, error } = useIssuingCardDetailsHook(cardId)
  const [isModalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (error) {
      toaster.error("Error fetching card details")
    }
  }, [error])

  if (!loading && !card) {
    navigate("../")
    return <></>
  }

  return (
    <>
      {card && (
        <>
          <LostCardModal cardId={card.id} isModalOpen={isModalOpen} onClose={() => setModalOpen(false)} />
          <Container>
            <Card>
              <CardHeader>
                <IssuingCardWithActions card={card} onOpenBlockModal={() => setModalOpen(true)} />
              </CardHeader>
              <CardContent>
                <IssuingCardUsage card={card} />
              </CardContent>
            </Card>
            <IssuingCardTransactionsTable transactions={transactions} isLoading={loading} />
          </Container>
        </>
      )}
    </>
  )
}
