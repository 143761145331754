import { useMutation } from "@apollo/client"
import {
  BankIcon,
  FloatingBox,
  GetPaidIcon,
  Invoices2Icon,
  Menu as KryptonMenu,
  MenuButtonEntry,
  MenuListButton,
  MenuOpenIcon,
  MenuProfileButton,
  MenuReduceIcon,
  PaidIcon,
  Typography,
} from "@hero/krypton"
import { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"
import { LanguageSelectMenuEntry } from "../00_shared/components/LanguageSelector"
import { useIsFFCWTActive } from "../00_shared/hooks/useFeatureFlag.hook"
import { unwrapGraphQLResponse } from "../01_technical/requesting/DEPRECATED_graphql.errors"
import { AvailableLanguage, EnabledLanguages, useDashboardTranslation } from "../01_technical/translations"
import { useAuthContext } from "../Auth/auth.context"
import heroLogo from "../assets/hero.svg"
import { UserLocale } from "../business/enums/User.enum"
import { BoSwitcher } from "./00_shared/components/BoSwitcher"
import { useMenuLayoutContext } from "./00_shared/components/MenuLayoutContext"
import { ProAccountMenuEntry, TransfersMenuEntry } from "./BusinessAccount/routes/listMenuEntry"
import { CollectionMenuEntry } from "./Collection/Collection.menuEntry"
import { CwtListMenuEntry } from "./CreditWireTransfer/List/List.menuEntry"
import {
  UPDATE_USER_LOCALE,
  UPDATE_USER_LOCALE_ARGS,
  UPDATE_USER_LOCALE_RESPONSE,
  UPDATE_USER_LOCALE_RESPONSE_SUCCESS,
  useIsCreditWireTransferActive,
} from "./merchant.menu.requests"
import { MerchantMenuEntry } from "./merchant.menu.type"
import { IssuingCardsMenuEntry } from "./IssuingCards/routes/listMenuEntry"

interface CollapsibleProps {
  $isMenuReduced: boolean
}

const LogoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  & img {
    width: 4rem;
    height: auto;
  }
`

const MenuContainer = styled(KryptonMenu)<CollapsibleProps>`
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  width: ${({ $isMenuReduced }) => ($isMenuReduced ? "4.25rem" : "15rem")};
  transition: width 0.3s;
  align-items: ${({ $isMenuReduced }) => ($isMenuReduced ? "center" : "inherit")};

  @media (max-width: 768px) {
    display: none;
  }
`

const MenuListButtonTitle = styled.div<CollapsibleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
  & .text {
    margin-left: 0.25rem;
    display: ${({ $isMenuReduced }) => ($isMenuReduced ? "none" : "inline")};
  }
`

const ScaleDownIcon = styled.div`
  display: flex;
  transform: scale(0.9);
`

const MenuListButtonWrapper = styled.div`
  margin: 0.25rem 0;
`

const fromCodeLangToUserLocale = (code: string): UserLocale => {
  switch (code) {
    case AvailableLanguage.EN:
      return UserLocale.EN
    case AvailableLanguage.DE:
      return UserLocale.DE
    case AvailableLanguage.FR:
      return UserLocale.FR
    case AvailableLanguage.ES:
      return UserLocale.ES
    case AvailableLanguage.IT:
      return UserLocale.IT
    default:
      throw new Error(`Unknown language code ${code}`)
  }
}

const useUpdateUserLocale = () => {
  const [updateUserLocale, { error, loading, data }] = useMutation<
    UPDATE_USER_LOCALE_RESPONSE,
    UPDATE_USER_LOCALE_ARGS
  >(UPDATE_USER_LOCALE)

  const { error: updateUserLocaleFunctionalError } = unwrapGraphQLResponse<UPDATE_USER_LOCALE_RESPONSE_SUCCESS>(
    data?.updateUserLocale,
  )

  return {
    updateUserLocale,
    updateUserLocaleLoading: loading,
    updateUserLocaleFunctionalError,
    updateUserLocaleTechnicalError: error,
  }
}

const ToggleButton = styled.button<CollapsibleProps>`
  display: flex;
  background-color: unset;
  align-items: center;
  margin: ${({ $isMenuReduced }) => ($isMenuReduced ? "auto" : "0")};
  border: none;
  cursor: pointer;
`

const StyledMenuListButton = styled(MenuListButton)<CollapsibleProps>`
  border-radius: ${({ $isMenuReduced }) => ($isMenuReduced ? "50%" : "0.5rem")};
  width: ${({ $isMenuReduced }) => ($isMenuReduced ? "2.25rem" : "auto")};
  height: ${({ $isMenuReduced }) => ($isMenuReduced ? "2.25rem" : "auto")};
  padding: ${({ $isMenuReduced }) => ($isMenuReduced ? "0" : "0 0.5rem 0 0.3rem")};
  display: flex;
  white-space: nowrap;
  justify-content: ${({ $isMenuReduced }) => ($isMenuReduced ? "center" : "unset")};
  align-items: ${({ $isMenuReduced }) => ($isMenuReduced ? "center" : "unset")};

  & .text {
    margin-left: 0.25rem;
    display: ${({ $isMenuReduced }) => ($isMenuReduced ? "none" : "inline")};
  }
`

const ReduceMenuContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`

const LogoAndToggleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const LogoImageContainer = styled.div`
  transition: all 0.6s ease;
`

export const Menu = () => {
  const { t, unsafeT, i18n } = useDashboardTranslation()
  const { currentUser } = useAuthContext()
  const location = useLocation()
  const [anchorElement, setAnchorEl] = useState<HTMLButtonElement>()
  const isOpen = Boolean(anchorElement)
  const isCWTEnabled = useIsFFCWTActive()
  const { updateUserLocale } = useUpdateUserLocale()
  const { isMenuReduced, setIsMenuReduced } = useMenuLayoutContext()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(anchorElement ? undefined : e.currentTarget)
  }

  const isActive = (path: string) => location.pathname.startsWith(path)

  const creditWireTransferMenuEntries: MerchantMenuEntry[] = [CwtListMenuEntry]

  const setLang = (code: string) => {
    updateUserLocale({ variables: { locale: fromCodeLangToUserLocale(code) } })
    i18n.changeLanguage(code)
  }

  const { data } = useIsCreditWireTransferActive({ merchantId: currentUser.merchantId })
  const isCWTEnabledForTheMerchant = data?.isActivated

  return (
    <MenuContainer $isMenuReduced={isMenuReduced} mode="light">
      {!isMenuReduced && (
        <MenuProfileButton onClick={handleClick} isOpen={isOpen} mode="light">
          {currentUser.merchantTradingName}
        </MenuProfileButton>
      )}

      <FloatingBox
        anchorElement={anchorElement}
        onClose={() => setAnchorEl(undefined)}
        isOpen={isOpen}
        // eslint-disable-next-line i18next/no-literal-string
        style={{ width: "14rem", zIndex: 9999 }}
      >
        {EnabledLanguages.map((code) => (
          <LanguageSelectMenuEntry
            key={code}
            mode="light"
            countryCode={code}
            onClick={() => setLang(code)}
            selected={i18n.language === code}
          />
        ))}
        <MenuButtonEntry<typeof Link> as={Link} to="/logout" color="danger" mode="light">
          {t("menu.logout")}
        </MenuButtonEntry>
      </FloatingBox>

      <MenuListButtonWrapper>
        <StyledMenuListButton
          $isMenuReduced={isMenuReduced}
          $variant="label-1"
          mode="light"
          selected={isActive(ProAccountMenuEntry.to)}
          key={ProAccountMenuEntry.to}
          as={Link}
          to={ProAccountMenuEntry.to}
        >
          <MenuListButtonTitle $isMenuReduced={isMenuReduced}>
            <ScaleDownIcon>
              <BankIcon />
            </ScaleDownIcon>
            <span className="text">{unsafeT(ProAccountMenuEntry.key)}</span>
          </MenuListButtonTitle>
        </StyledMenuListButton>
      </MenuListButtonWrapper>

      <MenuListButtonWrapper>
        <StyledMenuListButton
          $isMenuReduced={isMenuReduced}
          $variant="label-1"
          mode="light"
          selected={isActive(IssuingCardsMenuEntry.to)}
          key={IssuingCardsMenuEntry.to}
          as={Link}
          to={IssuingCardsMenuEntry.to}
        >
          <MenuListButtonTitle $isMenuReduced={isMenuReduced}>
            <ScaleDownIcon>
              <BankIcon />
            </ScaleDownIcon>
            <span className="text">{unsafeT(IssuingCardsMenuEntry.key)}</span>
          </MenuListButtonTitle>
        </StyledMenuListButton>
      </MenuListButtonWrapper>

      {isCWTEnabled && isCWTEnabledForTheMerchant && (
        <MenuListButtonWrapper>
          <StyledMenuListButton
            $isMenuReduced={isMenuReduced}
            mode="light"
            selected={isActive(creditWireTransferMenuEntries[0].to)}
            $variant="label-1"
            key={creditWireTransferMenuEntries[0].key}
            as={Link}
            to={creditWireTransferMenuEntries[0].to}
          >
            <MenuListButtonTitle $isMenuReduced={isMenuReduced}>
              <ScaleDownIcon>
                <Invoices2Icon />
              </ScaleDownIcon>
              <span className="text">{unsafeT(creditWireTransferMenuEntries[0].key)}</span>
            </MenuListButtonTitle>
          </StyledMenuListButton>
        </MenuListButtonWrapper>
      )}

      <MenuListButtonWrapper>
        <StyledMenuListButton
          $isMenuReduced={isMenuReduced}
          $variant="label-1"
          mode="light"
          selected={isActive(TransfersMenuEntry.to)}
          key={TransfersMenuEntry.to}
          as={Link}
          to={TransfersMenuEntry.to}
        >
          <MenuListButtonTitle $isMenuReduced={isMenuReduced}>
            <ScaleDownIcon>
              <PaidIcon />
            </ScaleDownIcon>
            <span className="text">{unsafeT(TransfersMenuEntry.key)}</span>
          </MenuListButtonTitle>
        </StyledMenuListButton>
      </MenuListButtonWrapper>

      <MenuListButtonWrapper>
        <StyledMenuListButton
          $isMenuReduced={isMenuReduced}
          $variant="label-1"
          mode="light"
          selected={isActive(CollectionMenuEntry.to)}
          key={CollectionMenuEntry.to}
          as={Link}
          to={CollectionMenuEntry.to}
        >
          <MenuListButtonTitle $isMenuReduced={isMenuReduced}>
            <ScaleDownIcon>
              <GetPaidIcon />
            </ScaleDownIcon>
            <span className="text">{unsafeT(CollectionMenuEntry.key)}</span>
          </MenuListButtonTitle>
        </StyledMenuListButton>
      </MenuListButtonWrapper>

      <LogoContainer>
        <BoSwitcher isReduced={isMenuReduced} />
        <LogoAndToggleContainer>
          <ToggleButton $isMenuReduced={isMenuReduced} onClick={() => setIsMenuReduced((isReduced) => !isReduced)}>
            {isMenuReduced && <MenuOpenIcon width={30} height={30} />}
            {!isMenuReduced && (
              <ReduceMenuContainer>
                <MenuReduceIcon width={30} height={30} />{" "}
                <Typography $variant="body-4-regular">{t("menu.reduce")}</Typography>
              </ReduceMenuContainer>
            )}
          </ToggleButton>
          {!isMenuReduced && (
            <LogoImageContainer>
              <img src={heroLogo} alt="" />
            </LogoImageContainer>
          )}
        </LogoAndToggleContainer>
      </LogoContainer>
    </MenuContainer>
  )
}
