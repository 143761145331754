import { Navigate, Outlet } from "react-router-dom"
import { balanceRoute } from "./Balance/Balance.route"
import { incomingTransactionDetailsRoute } from "./Balance/TransferDetails/Incoming/IncomingTransferDetails.route"
import { outgoingDetailsRoute } from "./Balance/TransferDetails/outgoing/outgoingDetails.route"
import { mrfRoute } from "./MarketplaceReserveFinancing/MarketplaceReserveFinancing.route"
import { paymentDetailsRoute } from "./Payments/PaymentDetails/PaymentDetails.route"
import { paymentsRoute } from "./Payments/Payments.route"
import { cockpitRoute } from "./cockpit/cockpit.route"

export const acceleratedPayoutsRoute = {
  path: "ap/*",
  children: [
    { index: true, element: <Navigate to="cockpit" /> },
    cockpitRoute,
    {
      path: "payments/*",
      element: <Outlet />,
      handle: {
        crumb: () => ({ label: "Payments", to: "/ap/payments" }),
      },
      children: [paymentsRoute, paymentDetailsRoute],
    },
    balanceRoute,
    {
      path: "transfers/*",
      element: <Outlet />,
      children: [
        { index: true, element: <Navigate to="/ap/balance" /> },
        incomingTransactionDetailsRoute,
        outgoingDetailsRoute,
      ],
    },
    { index: true, element: <Navigate to="marketplace-reserve-financing" /> },
    mrfRoute,
  ],
}
