import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

const CREATE_BUSINESS_ACCOUNT = gql`
  mutation CreateBusinessAccount($name: String!) {
    createBusinessAccount(name: $name) {
      ... on CreateBusinessAccountOutput {
        ledgerId
        name
      }
      ... on SimpleApiError {
        errorCode
      }
      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`

type CreateBusinessAccountOutput = {
  ledgerId: string
  name: string
}

type CreateBusinessAccountInput = {
  name: string
}

export const useCreateBusinessAccount = () => {
  const [mutate, { data, loading, error }] = useHeroMutation<CreateBusinessAccountOutput, CreateBusinessAccountInput>({
    gqlQuerySchema: CREATE_BUSINESS_ACCOUNT,
  })

  return { mutate, loading, error, data }
}
