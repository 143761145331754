/**
 * @public
 */
export enum MerchantStatus {
  WAITING_FOR_COMPLETION = "WAITING_FOR_COMPLETION",
  WAITING_FOR_VERIFICATION = "WAITING_FOR_VERIFICATION",
  VERIFIED = "VERIFIED",
  VERIFICATION_ERROR = "VERIFICATION_ERROR",
}

export enum MerchantFeesDocumentType {
  INVOICE = "INVOICE",
  VOUCHER = "VOUCHER",
}
