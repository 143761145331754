import { Navigate, RouteObject } from "react-router-dom"
import { IssuingCardsLayout } from "../00_shared/components/IssuingCardsLayout"
import { IssuingCardCreation } from "../IssuingCardCreation/IssuingCardCreation.screen"
import { stepsConfig } from "../IssuingCardCreation/IssuingCardCreationContext"
import { IssuingCardList } from "../IssuingCardList/IssuingCardList"
import { IssuingCardInformations } from "../IssuingCardList/components/IssuingCardInformations"
import { IssuingCardDetails } from "../issuingCardDetails/issuingCardDetails"

export const issuingCardsRoutes = {
  path: "cards",
  children: [
    {
      element: <IssuingCardsLayout />,
      children: [
        {
          index: true,
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          children: [
            {
              path: "",
              element: <IssuingCardList />,
              children: [
                {
                  path: "informations/:cardId",
                  element: <IssuingCardInformations />,
                },
              ],
            },
          ],
        },
        {
          path: "details/:cardId",
          element: <IssuingCardDetails />,
        },
        {
          path: "create",
          element: <IssuingCardCreation />,
          children: stepsConfig.reduce<RouteObject[]>(
            (acc, step) => {
              acc.push({
                path: step.path,
                element: step.element,
              })

              return acc
            },
            [
              {
                index: true,
                element: <Navigate to={stepsConfig[0].path} replace={true} />,
              },
            ],
          ),
        },
      ],
    },
  ],
}
