import { Button, Separator, Spinner, Typography, toaster } from "@hero/krypton"
import { useCallback, useEffect } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import linkLogo from "../../../../../assets/checkout_and_cash/link.png"
import { PAY_URI } from "../../../../../env_variables"
import { useSendPaymentLink } from "../CreateLink.requests"
import { useNavigateWithScrolling } from "../CreateLink.utils"
import { useCreateLinkContext } from "../CreateLinkContext"

const Image = styled.img`
  width: 6rem;
  height: auto;
`

const ScreenContainer = styled.div`
  height: 100vh;
  background: ${({ theme }) => theme.colors.white};
`

const PageWrapper = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 40rem;
  margin: 3rem auto;
`

const URL = styled(Typography)`
  width: 100%;
  overflow: auto;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 0.5rem;
  margin: 0.25rem 0 1.25rem 0;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
`

const DividerGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
`

const Divider = styled(Separator)`
  height: 1px;
`

const ActionsTextSeparator = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
  margin: 0 0.5rem;
`

const ButtonActionGroup = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 1.5rem;

  button {
    flex: 1;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

const BackButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
`

const Description = styled(Typography)`
  color: ${(props) => props.theme.colors.grey.$500};
`

export const LinkCreatePreviewStep = () => {
  const { t } = useDashboardTranslation()
  const { id: paymentLinkId } = useParams()
  const { paymentLink, fetchPaymentLink } = useCreateLinkContext()
  const [sendPaymentLink, { loading }] = useSendPaymentLink()
  const navigate = useNavigateWithScrolling()

  const url = `${PAY_URI}/link/${paymentLinkId}`

  useEffect(() => {
    if (!paymentLink && paymentLinkId) {
      fetchPaymentLink(paymentLinkId)
    }
  }, [fetchPaymentLink, navigate, paymentLink, paymentLinkId])

  useEffect(() => {
    if (paymentLink?.isDraft) {
      navigate(`/collection/new-link/${paymentLinkId}/demand`, { replace: true })
    }
  }, [navigate, paymentLink, paymentLinkId])

  useEffect(() => {
    if (paymentLink === null) {
      navigate("/collection/new-link")
    }
  }, [navigate, paymentLink])

  const sendPaymentLinkWithMethod = useCallback(
    (method: "SMS" | "WHATSAPP") => async () => {
      if (!paymentLinkId) {
        return
      }

      try {
        await sendPaymentLink({ variables: { input: { paymentLinkId, method } } })
        toaster.success(t(`bnpl.link.wizard.steps.preview.toaster.success.${method}`))
      } catch {
        toaster.error(t(`bnpl.link.wizard.steps.preview.toaster.error.${method}`))
      }
    },
    [paymentLinkId, sendPaymentLink, t],
  )

  if (!paymentLink) {
    return <Spinner />
  }

  return (
    <ScreenContainer>
      <PageWrapper>
        <Image src={linkLogo} alt="" />
        <Typography $variant="title-1-bold">{t("bnpl.link.wizard.steps.preview.title")}</Typography>
        <Description $variant="body-4-regular">{t("bnpl.link.wizard.steps.preview.subtitle")}</Description>
        <Description $variant="body-4-regular">{t("bnpl.link.wizard.steps.preview.description")}</Description>
        <Typography $variant="body-4-medium">{t("bnpl.link.wizard.steps.preview.urlTitle")}</Typography>
        <URL as="pre" $variant="body-4-regular" data-test-id="payment-link-url">
          <code>{url}</code>
        </URL>

        {/** eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/** @ts-expect-error CopyToClipboardType is wrong... */}
        <CopyToClipboard
          text={url}
          onCopy={(_: unknown, result?: unknown) => {
            if (result) {
              toaster.success(t("bnpl.link.wizard.steps.preview.urlCopy.toasterSuccess"), {
                autoClose: 1500,
              })
            } else {
              toaster.error(t("bnpl.link.wizard.steps.preview.urlCopy.toasterError"))
            }
          }}
        >
          <Button isLoading={false} style={{ width: "100%" }}>
            {t("bnpl.link.wizard.steps.preview.urlCopyButton")}
          </Button>
        </CopyToClipboard>
        <Actions>
          <DividerGroup>
            <Divider />
            <ActionsTextSeparator $variant="label-1">
              {t("bnpl.link.wizard.steps.preview.urlDivider").toUpperCase()}
            </ActionsTextSeparator>
            <Divider />
          </DividerGroup>

          <ButtonActionGroup>
            <Button $variant="secondary" onClick={sendPaymentLinkWithMethod("SMS")} isLoading={loading}>
              {t("bnpl.link.wizard.steps.preview.urlSendSMSButton")}
            </Button>
            <Button $variant="secondary" onClick={sendPaymentLinkWithMethod("WHATSAPP")} isLoading={loading}>
              {t("bnpl.link.wizard.steps.preview.urlSendWhatsAppButton")}
            </Button>
          </ButtonActionGroup>
        </Actions>

        <BackButtonContainer>
          <Button $variant="underline" onClick={() => navigate("/collection")}>
            {t("bnpl.link.wizard.steps.preview.backButton")}
          </Button>
        </BackButtonContainer>
      </PageWrapper>
    </ScreenContainer>
  )
}
