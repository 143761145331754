import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { AccountDetailsTabs } from "./components/AccountDetailsTabs"

const Container = styled.div`
  display: grid;
  grid-template-columns: 18rem minmax(0, 1fr);
  gap: 1.5rem;

  @media (max-width: 768px) {
    grid-template-columns: auto;
  }
`

const AccountDetailsTabsWrapper = styled.div`
  align-self: start;
`

export const AccountDetailsRoot = () => {
  return (
    <Container>
      <AccountDetailsTabsWrapper>
        <AccountDetailsTabs />
      </AccountDetailsTabsWrapper>
      <Outlet />
    </Container>
  )
}
