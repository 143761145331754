import { gql } from "@apollo/client"
import { DateTime } from "luxon"
import { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { useDebounce } from "../../../../../00_shared/hooks/useDebounce.hooks"
import { toEuros } from "../../../../../00_shared/utils/currency.converter"
import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { useCommonTranslation } from "../../../../../01_technical/translations"
import { UserLocale } from "../../../../../business/enums/User.enum"
import { MerchantOperation, OperationType } from "../../../00_shared/business"

const GET_MERCHANT_OPERATIONS = gql`
  query getMerchantOperations($pagination: GetMerchantOperationsPagination!, $filters: GetMerchantOperationsFilters) {
    getMerchantOperations(pagination: $pagination, filters: $filters) {
      ... on GetMerchantOperationsOutput {
        operations {
          id
          amount
          creditorId
          creditorName
          debtorId
          debtorName
          reference
          operationType
          createdAt
          ledgerType
          isExternalTransfer
        }
        limit
        total
        hasNext
        currentPage
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export interface ExternalTransfer {
  id: string
  beneficiary?: string
  sentAt: string
  amount: string
  reference?: string
}

interface MerchantOperationsPagination {
  limit?: number
  page: number
}

interface MerchantOperationsPaginationResult {
  limit: number
  total: number
  hasNext: boolean
  currentPage: number
}

interface MerchantOperationsFilters {
  productLedgerIds?: string[]
  operationTypes?: OperationType[]
  amount?: {
    equal?: number
    greaterThan?: number
    lessThan?: number
  }
  date?: {
    from?: Date
    to?: Date
  }
}

interface MerchantOperationsVariables {
  pagination: MerchantOperationsPagination
  filters?: MerchantOperationsFilters
}

const externalTransfersOperations = [OperationType.ACCOUNT_TO_ACCOUNT_OUT, OperationType.SEPA_CREDIT_TRANSFER_OUT]

const initPagination = { page: 1, limit: 10 }

export const useExternalTransfers = (
  initialPagination: MerchantOperationsPagination = initPagination,
  initialFilters?: MerchantOperationsFilters,
) => {
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const { i18n } = useCommonTranslation()
  const [externalTransfers, setExternalTransfers] = useState<ExternalTransfer[]>([])
  const [variables, setVariables] = useState<MerchantOperationsVariables>({
    pagination: initialPagination,
    filters: initialFilters,
  })

  console.log(`state: ${location.state?.freezeExternalTransfers}`)

  const { data, loading, error, refetch } = useHeroQuery<
    { operations: MerchantOperation[] } & MerchantOperationsPaginationResult
  >({
    gqlQuerySchema: GET_MERCHANT_OPERATIONS,
    variables: {
      pagination: variables.pagination,
      filters: { ...variables.filters, operationTypes: externalTransfersOperations },
    },
    skip: location.state?.freezeExternalTransfers,
    fetchPolicy: "cache-and-network",
  })
  const [isLoading] = useDebounce(loading, 300)

  const locale = useMemo(() => UserLocale[i18n.language as keyof typeof UserLocale], [i18n.language])

  useEffect(() => {
    if (data?.operations) {
      const externalTransfers = data?.operations
        // .filter((operation) => operation.isExternalTransfer)
        .map((operation) => formatExternalTransferOperations({ operation, locale }))
      setExternalTransfers(externalTransfers)
    }
  }, [data?.operations, locale])

  useEffect(() => {
    setVariables({
      pagination: {
        page: Number(searchParams.get("page")) || initPagination.page,
        limit: initPagination.limit,
      },
    })
  }, [searchParams])

  return {
    externalTransfers,
    pagination: {
      limit: data?.limit || 0,
      total: data?.total || 0,
      currentPage: data?.currentPage || 1,
      totalPages: Math.ceil((data?.total || 0) / (data?.limit || 1)),
      hasNext: data?.hasNext || false,
    },
    loading: isLoading,
    error,
    variables,
    setVariables,
    refetch,
  }
}

interface FormatOperationOptionsProps {
  operation: MerchantOperation
  locale: UserLocale
}

const formatExternalTransferOperations = ({ operation, locale }: FormatOperationOptionsProps) => {
  return {
    id: operation.id,
    beneficiary: operation.creditorName,
    sentAt: DateTime.fromJSDate(new Date(operation.createdAt)).setLocale(locale).toLocaleString(DateTime.DATE_MED),
    amount: toEuros(operation.amount),
    reference: operation.reference,
  }
}
