import { Navigate, Outlet } from "react-router-dom"
import { TransactionScreen } from "./transaction.screen"

export const transactionRoute = {
  path: "transactions/*",
  element: <Outlet />,
  children: [
    { index: true, element: <Navigate to="cockpit" /> },
    { path: ":id", element: <TransactionScreen /> },
  ],
}
