import { Spinner, Table, TBody, THead, Typography } from "@hero/krypton"
import styled from "styled-components"
import { ScrollArea } from "../../../../../00_shared/components/ScrollArea"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { PaginationContainer } from "../../../00_shared/components/PaginationContainer"
import { Beneficiary, PaginationResult } from "../hooks/useBeneficiaries"

const TableContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  border-top: none;
`

const StaticTable = styled(Table)`
  overflow-x: hidden;
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`

const CenteringTD = styled.td`
  text-align: center;
`

interface BeneficiariesTableProps {
  beneficiaries: Beneficiary[]
  pagination: PaginationResult
  isLoading: boolean
  onPageChange: (pageNumber: number) => void
}

export const BeneficiariesTable = ({ beneficiaries, pagination, isLoading, onPageChange }: BeneficiariesTableProps) => {
  const { t } = useDashboardTranslation()

  return (
    <ScrollArea height="100%">
      <TableContainer>
        <StaticTable>
          <THead>
            <tr>
              <th>{t("beneficiary.label.name")}</th>
              <th>{t("beneficiary.label.iban")}</th>
              <th>{t("beneficiary.label.bank")}</th>
            </tr>
          </THead>
          <TBody $clickable={!!beneficiaries.length}>
            {isLoading && (
              <tr>
                <CenteringTD colSpan={4}>
                  <Spinner />
                </CenteringTD>
              </tr>
            )}
            {!beneficiaries.length && !isLoading && (
              <tr>
                <CenteringTD colSpan={4}>{"No beneficiaries found."}</CenteringTD>
              </tr>
            )}
            {beneficiaries.map((beneficiary) => (
              <tr key={beneficiary.id}>
                <td>
                  <Typography $variant="body-4-regular">{beneficiary.label}</Typography>
                </td>
                <td>
                  <Typography $variant="body-4-regular">{beneficiary.iban}</Typography>
                </td>
                <td>
                  <Typography $variant="body-4-regular">{beneficiary.bankName || "-"}</Typography>
                </td>
              </tr>
            ))}
          </TBody>
        </StaticTable>
        <PaginationContainer
          currentPage={pagination.currentPage}
          totalPages={pagination.totalPage}
          onPageChange={(pageNumber: number) => onPageChange(pageNumber)}
        />
      </TableContainer>
    </ScrollArea>
  )
}
