import { gql } from "@apollo/client"
import { useCallback, useEffect, useState } from "react"
import { HeroApiError } from "../../../../../../01_technical/requesting/request-error.type"
import { useHeroQuery } from "../../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"

const GET_BENEFICIARIES = gql`
  query listBeneficiaries($filters: ListBeneficiariesFilters, $pagination: ListBeneficiariesPaginationInput!) {
    listBeneficiaries(filters: $filters, pagination: $pagination) {
      ... on ListBeneficiariesOutput {
        beneficiaries {
          id
          label
          isDefault
          fullName
          status
          iban
          bic
          linkedMerchantAccounts {
            id
            label
            accountId
          }
          bankName
        }
        pagination {
          totalCount
          totalPage
          currentPage
        }
      }

      ... on SimpleApiError {
        errorCode
      }

      ... on ValidationErrors {
        errorCode
      }
    }
  }
`

interface Beneficiary {
  id: string
  merchantId: string
  label: string
  isDefault: boolean
  fullName: string
  status: string
  iban: string
  bankName?: string
  last4Iban: string
  bic: string
}

interface PaginationResult {
  totalCount: number
  totalPage: number
  currentPage: number
}

interface BeneficiariesQueryResult {
  beneficiaries: Beneficiary[]
  pagination: PaginationResult
}

interface BeneficiariesVariables {
  pagination: {
    pageSize: number
    pageNumber: number
  }
}

interface UseBeneficiariesResult {
  beneficiaries: Beneficiary[]
  pagination: PaginationResult
  loading: boolean
  error: HeroApiError | null
  refetch: () => void
  fetchMoreBeneficiaries: () => void
  setVariables: React.Dispatch<React.SetStateAction<BeneficiariesVariables>>
}

const initPagination = { limit: 10, page: 1 }
const defaultPaginationResult = { totalCount: 0, totalPage: 0, currentPage: 0 }

export const useBeneficiariesForTransfer = (
  initialPagination: { limit: number; page: number } = initPagination,
): UseBeneficiariesResult => {
  const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([])
  const [pagination, setPagination] = useState<PaginationResult>(defaultPaginationResult)
  const [variables, setVariables] = useState<BeneficiariesVariables>({
    pagination: { pageNumber: initialPagination.page, pageSize: initialPagination.limit },
  })

  const { data, loading, error, refetch, fetchMore } = useHeroQuery<BeneficiariesQueryResult>({
    gqlQuerySchema: GET_BENEFICIARIES,
    variables: variables,
    fetchPolicy: "cache-and-network",
  })

  // Update state when data changes
  useEffect(() => {
    if (data) {
      setBeneficiaries((prevBeneficiaries) => {
        const existingIds = new Set(prevBeneficiaries.map((b) => b.id))
        const newBeneficiaries = data.beneficiaries.filter((b) => !existingIds.has(b.id))
        return [...prevBeneficiaries, ...newBeneficiaries]
      })
      setPagination(data.pagination)
    }
  }, [data])

  const fetchMoreBeneficiaries = useCallback(() => {
    if (pagination.currentPage < pagination.totalPage && !loading) {
      const nextPage = pagination.currentPage + 1
      fetchMore({
        variables: { pagination: { pageNumber: nextPage, pageSize: variables.pagination.pageSize } },
        updateQuery: (
          previousResult: { listBeneficiaries: BeneficiariesQueryResult },
          { fetchMoreResult }: { fetchMoreResult: { listBeneficiaries: BeneficiariesQueryResult } },
        ) => {
          if (!fetchMoreResult) return previousResult
          return fetchMoreResult // Return new results and let useEffect handle the state update
        },
      })
    }
  }, [pagination, loading, fetchMore, variables.pagination.pageSize])

  return {
    beneficiaries,
    pagination,
    loading,
    error,
    refetch,
    fetchMoreBeneficiaries,
    setVariables,
  }
}
