import { Header, InfoIcon, Separator, Spinner, Tab, Tabs, Typography } from "@hero/krypton"
import { FC, useEffect, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { useIsFFMRFActive } from "../../../../00_shared/hooks/useFeatureFlag.hook"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { checkIfIsDemoMerchant } from "../../../../00_shared/utils/demo"
import { HeroErrorDisplayer } from "../../../../01_technical/requesting/DEPRECATED_graphql.errors"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"
import { HEADER_COLORS } from "../../../../env_variables"
import { LogoMarketplace, NoLogoMarketplaceLogoIcon } from "../../00_shared/components/MarketplaceTabsLogos"
import { GET_MRF_KPIS_RESPONSE_SUCCESS } from "../cockpitWithNewKPI.requests"
import { useGetKpis } from "../hooks/useGetKpis.hooks"
import { useGetMRFKpis } from "../hooks/useGetMRFKpis.hooks"
import { useGetMarketplaces } from "../hooks/useGetMarketplaces.hooks"
import { CockpitBarChartWithNewKPI } from "./CockpitBarChartWithNewKPI"
import { KPIContainer } from "./KPIContainer"

const Container = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
  border-left: 1px solid ${({ theme }) => theme.colors.grey.$300};
  padding-bottom: 2rem;
`

const HeaderMain = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const Nav = styled.nav`
  margin-top: 1rem;

  div[role="tablist"] {
    border-bottom: none;
  }

  a[role="tab"] {
    padding: 0;
    margin-right: 1rem;
    min-height: 0;
    padding-bottom: 1rem;
  }
`

const IconTooltip = styled(InfoIcon)`
  width: 1.2rem;
  height: 1.2rem;
`

const BalanceContainer = styled.div`
  position: relative;
`

const BalanceTooltip = styled.dialog`
  padding: 0.7rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  border: 1px solid ${({ theme }) => theme.colors.grey.$300};
  box-shadow: ${({ theme }) => theme.shadows.mid};
  min-width: 18.75rem;

  flex-direction: column;

  &[open] {
    display: flex;
  }

  &:not([open]) {
    display: none;
  }
`

const BalanceTooltipMarketplace = styled.div`
  display: flex;
  justify-content: space-between;
`

const MainContent = styled.div`
  margin: 0 3rem;
`

const KPISContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1.5rem 0;
  gap: 1.25rem;
  flex-wrap: wrap;

  & > div {
    position: relative;
    flex: 1;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 1.5rem 2rem;
    border-radius: 1rem;
    border: 1px solid ${({ theme }) => theme.colors.grey.$300};
    box-shadow: ${({ theme }) => theme.shadows.mid};
  }
`

type MRFKPIsParams = {
  loading: boolean
  kpis?: GET_MRF_KPIS_RESPONSE_SUCCESS
}
const MRFKpis: FC<MRFKPIsParams> = ({ loading, kpis }) => {
  const { t } = useDashboardTranslation()

  if (!kpis || kpis.dueAmount === 0) {
    return null
  }

  return (
    <>
      <KPIContainer
        title={t("ap.cockpit.kpis.fundedAmount.title")}
        tooltip={t("ap.cockpit.kpis.fundedAmount.tooltip")}
        loading={loading}
        amount={kpis?.fundedAmount}
      />
      <KPIContainer
        title={t("ap.cockpit.kpis.dueAmount.title")}
        tooltip={t("ap.cockpit.kpis.dueAmount.tooltip")}
        loading={loading}
        amount={kpis?.dueAmount}
      />
    </>
  )
}

export const FilledAPCockpitWithNewKPI = () => {
  const [params, setSearchParams] = useSearchParams()
  const { t } = useDashboardTranslation()
  const period = params.get("period") ?? ""
  const marketplace = params.get("marketplace")
  const isFFMRFActive = useIsFFMRFActive()
  const { currentUser } = useAuthContext()
  const isDemoMerchant = checkIfIsDemoMerchant(currentUser.merchantId)

  const {
    marketplaces,
    totalAccountsBalance,
    getMarketplacesFunctionalError,
    getMarketplacesTechnicalError,
    getMarketplacesLoading,
  } = useGetMarketplaces({
    useDemoData: isDemoMerchant,
  })

  const accountId = marketplace === "all" ? undefined : marketplaces?.find((m) => m.name === marketplace)?.accountId

  useEffect(() => {
    if (!period || !marketplace) {
      setSearchParams({ marketplace: "all", period: "thisWeek" })
    }
  }, [period, marketplace, setSearchParams])

  const [isHoveringBalanceTooltip, setIsHoveringBalanceTooltip] = useState(false)

  const { kpis, getKpisTechnicalError, getKpisFunctionalError, getKpisLoading } = useGetKpis(
    { accountId },
    { useDemoData: isDemoMerchant },
  )

  const {
    data,
    loading,
    functionalError: mrfKpisFunctionalError,
    technicalError: mrfKpisTechnicalError,
  } = useGetMRFKpis({ accountId })

  const marketplacesTabs = [
    {
      name: "all",
      logoUrl: null,
    },
    ...(marketplaces ?? []),
  ]

  return (
    <Container data-test-id="merchant-ap-cockpit">
      {getMarketplacesLoading || getKpisLoading ? <Spinner></Spinner> : null}
      <Header $colors={HEADER_COLORS}>
        <HeaderMain>
          {totalAccountsBalance !== undefined && (
            <BalanceContainer>
              <Typography $variant="title-3-semibold">
                {toEuros(totalAccountsBalance)}
                <IconTooltip
                  onMouseEnter={() => {
                    setIsHoveringBalanceTooltip(true)
                  }}
                  onMouseLeave={() => {
                    setIsHoveringBalanceTooltip(false)
                  }}
                />
              </Typography>
              <BalanceTooltip open={isHoveringBalanceTooltip}>
                {marketplaces?.map(({ name, accountBalance }) => (
                  <BalanceTooltipMarketplace key={name}>
                    {/* eslint-disable-next-line i18next/no-literal-string */}
                    <Typography $variant="caption-2">Account - {name}</Typography>
                    <Typography $variant="caption-1">{toEuros(accountBalance)}</Typography>
                  </BalanceTooltipMarketplace>
                ))}
              </BalanceTooltip>
            </BalanceContainer>
          )}
        </HeaderMain>
        <Nav>
          <Tabs>
            {marketplacesTabs.map(({ name, logoUrl }) => (
              <Tab<typeof Link>
                as={Link}
                $isSelected={name === marketplace}
                to={`/ap/cockpit?marketplace=${name}&period=${period}`}
                key={name}
                data-test-id={`merchant-ap-cockpit-tab-${name}`}
              >
                {logoUrl && <LogoMarketplace src={logoUrl} alt={name} />}
                {!logoUrl && name !== "all" && <NoLogoMarketplaceLogoIcon />}
                {name === "all" ? t("ap.cockpit.tabs.allAccounts") : name}
              </Tab>
            ))}
          </Tabs>
        </Nav>
      </Header>
      <Separator />
      <HeroErrorDisplayer
        err={getMarketplacesTechnicalError || getKpisTechnicalError}
        data={getMarketplacesFunctionalError || getKpisFunctionalError}
        messages={{
          UNAUTHORIZED: "Vous n'êtes pas autorisé à accéder à cette page",
          INTERNAL_SERVER_ERROR:
            "Nous avons rencontré un problème technique innatendu. Milles excuses, nous avons été averti.",
        }}
      />
      <HeroErrorDisplayer
        err={mrfKpisTechnicalError}
        data={mrfKpisFunctionalError}
        messages={{
          UNAUTHORIZED: "Vous n'êtes pas autorisé à accéder à cette page",
        }}
      />
      <MainContent>
        <KPISContainer data-test-id="merchant-ap-cockpit-kpis">
          <KPIContainer
            title={t("ap.cockpit.pendingApAmount")}
            tooltip={t("ap.cockpit.kpis.pendingApAmount.tooltip")}
            loading={getKpisLoading}
            amount={kpis?.totalPendingApAmount}
          />
          <KPIContainer
            title={t("ap.cockpit.heroPaymentVolume")}
            tooltip={t("ap.cockpit.kpis.createdPayments.tooltip")}
            loading={getKpisLoading}
            amount={kpis?.totalApFundedAmount}
          />
          <KPIContainer
            title={t("ap.cockpit.kpis.outgoingTransfers.title")}
            tooltip={t("ap.cockpit.kpis.outgoingTransfers.tooltip")}
            loading={getKpisLoading}
            amount={kpis?.totalApBankTransferAmount}
          />
          {isFFMRFActive && <MRFKpis loading={loading} kpis={data} />}
        </KPISContainer>

        <CockpitBarChartWithNewKPI selectedMarketplace={marketplace} />
      </MainContent>
    </Container>
  )
}
