import { ArrowLeftIcon, Button, Field, Label, Toggle, Typography } from "@hero/krypton"
import React, { useEffect, useMemo } from "react"
import styled from "styled-components"
import { Card, CardContent, CardFooter, CardHeader } from "../../../../../00_shared/components/Card"
import { FlexContainer, FlexItem } from "../../../../../00_shared/components/Flex"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../00_shared/components/Select"
import { centsToEuros, eurosToCents, toEuros } from "../../../../../00_shared/utils/currency.converter"
import { useCommonTranslation } from "../../../../../01_technical/translations"
import HeroIcon from "../../../../../assets/hero-icon.svg"
import { useBusinessAccountContext } from "../../../businessAccount.context"
import { useBusinessAccounts } from "../00_shared/hooks/useBusinessAccounts"
import { TransferType, useCreateTransferContext } from "../CreateTransferContext"

const DebitAccountSelectTrigger = styled(SelectTrigger)`
  position: relative;
  padding-left: 2rem;
  > span {
    text-align: start;
    width: 100%;
    > p {
      display: flex;
      justify-content: space-between;
    }
  }

  &::before {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background-image: url(${HeroIcon});
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 0.5rem;
  }
`

const DebitAccountSelectItem = styled(SelectItem)`
  width: 100%;

  > span {
    width: 100%;

    > p {
      display: flex;
      justify-content: space-between;
    }
  }
`

const AccountAmount = styled(Typography).attrs(() => ({
  $variant: "body-4-regular",
}))`
  color: ${({ theme }) => theme.colors.grey.$500};
  padding-right: 0.25rem;
`

const OptionsCard = styled(Card)`
  padding: 0;
  border: none;
`
const OptionsHeader = styled(CardHeader)`
  padding-left: 0;
  padding-right: 0;
`

const OptionsContent = styled(CardContent)`
  padding-left: 0;
  padding-right: 0;
`

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const OptionItem = styled(Card)`
  padding: 0.5rem 0.75rem;
`

export const TransferInformations: React.FC = () => {
  const { isBaActive } = useBusinessAccountContext()
  const { state, setState, handleNextStep, goToPreviousStep, validateAllPreviousSteps, errors } =
    useCreateTransferContext()
  const { businessAccounts } = useBusinessAccounts()
  const { t } = useCommonTranslation()

  const filteredAccounts = useMemo(
    () =>
      state.selectedTransferType === TransferType.ACCOUNT_TO_ACCOUNT
        ? businessAccounts.filter((account) => account.ledgerId !== state.accountToCreditId)
        : businessAccounts,
    [state.selectedTransferType, state.accountToCreditId, businessAccounts],
  )

  const handleAmountChange = (field: keyof typeof state) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, [field]: eurosToCents(Number(event.target.value)) }))
  }

  const handleReferenceChange = (field: keyof typeof state) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, [field]: event.target.value }))
  }

  const handleSelectChange = (value: string) => {
    const selectedAccount = businessAccounts.find((account) => account.ledgerId === value)
    if (selectedAccount) {
      setState((prevState) => ({
        ...prevState,
        accountToDebitId: selectedAccount.ledgerId,
        accountToDebitName: selectedAccount.name,
        accountToDebitIban: selectedAccount.VIban,
      }))
    }
  }

  const handleToggleChange = (field: keyof typeof state) => () => {
    setState((prevState) => ({ ...prevState, [field]: !prevState[field] }))
  }

  useEffect(() => {
    if (state.accountToCreditId === state.accountToDebitId) {
      setState((prevState) => ({ ...prevState, accountToDebit: "" }))
    }
  }, [state.accountToCreditId, state.accountToDebitId, setState])

  useEffect(() => {
    validateAllPreviousSteps()
  }, [validateAllPreviousSteps])

  return (
    <Card $variant="plain">
      <CardHeader>
        <Typography $variant="title-2-bold">{t("transfer.wizard.label.send")}</Typography>
      </CardHeader>
      <CardContent>
        <Field
          type="number"
          $fullWidth
          fieldLabel={t("transfer.details.amount")}
          placeholder={t("transfer.details.amount.placeholder")}
          value={centsToEuros(state.amount)}
          onChange={handleAmountChange("amount")}
          errorMessage={errors.amount}
          disabled={!isBaActive}
        />

        <Select
          error={!!errors.accountToDebitId}
          errorMessage={errors.accountToDebitId}
          onValueChange={handleSelectChange}
          value={state.accountToDebitId}
          disabled={!isBaActive}
        >
          <Label>{t("transfer.details.debitAccount")}</Label>
          <DebitAccountSelectTrigger>
            <SelectValue placeholder={t("transfer.details.debitAccount.select.placeholder")} />
          </DebitAccountSelectTrigger>
          <SelectContent>
            {filteredAccounts.map((businessAccount) => (
              <DebitAccountSelectItem key={businessAccount.ledgerId} value={businessAccount.ledgerId}>
                <Typography>{businessAccount.name}</Typography>
                <AccountAmount>{toEuros(businessAccount.balance)}</AccountAmount>
              </DebitAccountSelectItem>
            ))}
          </SelectContent>
        </Select>
        <Field
          type="text"
          $fullWidth
          fieldLabel={t("transfer.details.reference")}
          placeholder={t("transfer.details.reference.placeholder")}
          value={state.reference}
          onChange={handleReferenceChange("reference")}
          errorMessage={errors.reference}
          disabled={!isBaActive}
        />

        <OptionsCard $variant="plain">
          <OptionsHeader>
            <Typography $variant="title-2-bold">{t("transfer.details.options.title")}</Typography>
          </OptionsHeader>
          <OptionsContent>
            <OptionsContainer>
              <OptionItem>
                <FlexContainer $align="center" $justify="space-between">
                  <Typography>{t("transfer.details.instantTransfer")}</Typography>
                  <Toggle
                    checked={true}
                    onClick={handleToggleChange("instantTransfer")}
                    aria-label="Toggle instant transfer"
                    disabled={!isBaActive}
                  />
                </FlexContainer>
              </OptionItem>
              <OptionItem>
                <FlexContainer $align="center" $justify="space-between">
                  <Typography>{t("transfer.details.invoice")}</Typography>
                  <Toggle checked={false} aria-label="Toggle add invoice" disabled={!isBaActive} />
                </FlexContainer>
              </OptionItem>
            </OptionsContainer>
          </OptionsContent>
        </OptionsCard>
      </CardContent>
      <CardFooter>
        <FlexItem $grow={1}>
          <FlexContainer $direction="column" $gap="2rem">
            <Button $fullWidth $variant="primary" size="medium" onClick={handleNextStep} disabled={!isBaActive}>
              {t("transfer.wizard.label.next")}
            </Button>
            <FlexItem $alignSelf="center">
              <Button type="button" size="medium" $variant="underline" onClick={goToPreviousStep}>
                <ArrowLeftIcon />
                {t("transfer.wizard.label.back")}
              </Button>
            </FlexItem>
          </FlexContainer>
        </FlexItem>
      </CardFooter>
    </Card>
  )
}
