import { AddIcon, Button } from "@hero/krypton"
import { useMemo } from "react"
import { Outlet, useLocation, useNavigate, useNavigation } from "react-router-dom"
import styled from "styled-components"
import { EmptyState } from "../../../../00_shared/components/EmptyState"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import useMediaQuery from "../../../../00_shared/hooks/useMediaQuery.hook"
import { useCommonTranslation } from "../../../../01_technical/translations"
import { useBusinessAccountContext } from "../../businessAccount.context"
import ExternalTransferIcon from "../00_shared/icons/external-transfer-icon.png"
import { TransferHistoryList } from "./components/TransferHistoryList"
import { TransferHistoryTable } from "./components/TransferHistoryTable"
import { useExternalTransfers } from "./hooks/useExternalTransfers"

const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  gap: 0.5rem;
  button {
    min-width: unset;
  }

  @media (max-width: 768px) {
    top: 1.25rem;
    right: 1rem;
  }
`

export const TransferHistory = () => {
  const { isBaActive } = useBusinessAccountContext()
  const navigate = useNavigate()
  const { state } = useNavigation()
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const isMobile = useMediaQuery("(max-width: 768px)")
  const { t } = useCommonTranslation()
  const { externalTransfers, loading, pagination } = useExternalTransfers()

  const handleCreateTransfer = () => {
    navigate("/transfers/create")
  }

  const onPageChange = (pageNumber: number) => {
    searchParams.set("page", pageNumber.toString())
    navigate({ search: searchParams.toString() })
  }

  if (externalTransfers.length === 0 && !loading && state === "idle") {
    console.log("d")
    return (
      <EmptyState
        width="22rem"
        illustration={<img src={ExternalTransferIcon} alt="External transfer" width={100} />}
        title="Historique des virements sortants"
        text="Accédez à l'historique complet de vos virements sortants pour une gestion transparente de vos opérations financières. Chaque transaction est enregistrée pour vous donner une vue d'ensemble précise de l'activité de votre compte et faciliter votre suivi comptable."
        buttonText="Effectuer un virement"
        buttonIcon={<AddIcon />}
        onButtonClick={() => handleCreateTransfer()}
        disabledButton={!isBaActive}
      />
    )
  }

  return (
    <>
      {!isMobile && (
        <>
          <TransferHistoryTable
            isLoading={loading}
            externalTransfers={externalTransfers}
            pagination={pagination}
            onPageChange={onPageChange}
          />
        </>
      )}
      {isMobile && (
        <>
          <ScrollArea height="100%">
            <TransferHistoryList externalTransfers={externalTransfers} isLoading={loading} />
          </ScrollArea>
          <ButtonContainer>
            <Button size="small" isLoading={false} onClick={() => navigate("/transfers/new")} disabled={!isBaActive}>
              {t("wire.history.action")}
            </Button>
          </ButtonContainer>
        </>
      )}
      <Outlet />
    </>
  )
}
