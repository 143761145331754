import { Button, Separator, Typography } from "@hero/krypton"
import { useCallback, useState } from "react"
import { useNavigate, useNavigationType, useParams } from "react-router-dom"
import styled from "styled-components"
import CenteredLoading from "../../../../00_shared/components/CenteredLoading"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../../../../00_shared/components/Sheet"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { IssuingCardBadge } from "../../00_shared/components/IssuingCardBadge"
import { IssuingCardUsage } from "../../00_shared/components/IssuingCardUsage"
import { IssuingCardWithActions } from "../../00_shared/components/IssuingCardWithActions"
import { LostCardModal } from "../../00_shared/components/LostCardModal"
import { useIssuingCardInformationHook } from "../IssuingCardInformations.hook"

const FullSeparatorDashed = styled(Separator)`
  position: relative;
  left: -1.5rem;
  width: calc(100% + 3.5rem);
  border-style: dashed;
  margin-bottom: 1rem;
`

const ContentContainer = styled.div`
  display: grid;
  height: 100%;
  min-height: 0;
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto) minmax(0, 1fr);
  gap: 1rem;
  padding: 0 10px 0 1px;
`

const InformationsTitle = styled(SheetTitle)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 5rem;

  *:nth-child(1) {
    align-self: center;
  }

  *:nth-child(1) {
    :center;
  }
`

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: max-content;
  margin-top: auto;
  padding-bottom: 1px;
`

export const IssuingCardInformations = () => {
  const { t } = useDashboardTranslation()
  const navigate = useNavigate()
  const navigationType = useNavigationType()

  const [isOpen, setIsOpen] = useState(true)
  const [isModalOpen, setModalOpen] = useState(false) // State for modal
  const { cardId } = useParams<{ cardId: string }>()

  const { card, loading } = useIssuingCardInformationHook(cardId)

  const goToCardDetails = () => {
    navigate(`../../details/${cardId}`)
  }

  const navigateBack = useCallback(() => {
    if (!isOpen) {
      if (navigationType === "PUSH") {
        navigate(-1)
      } else {
        navigate("..")
      }
    }
  }, [isOpen, navigate, navigationType])

  const handleAnimationEnd = () => {
    navigateBack()
  }

  if (loading) {
    return (
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetContent side="right" onAnimationEndCapture={handleAnimationEnd}>
          <SheetHeader>
            <SheetTitle></SheetTitle>
          </SheetHeader>
          <CenteredLoading />
        </SheetContent>
      </Sheet>
    )
  }

  // If no card, navigate back
  if (!card) {
    navigateBack()
    return null
  }

  if (!cardId) {
    navigate("..")
    return
  }

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetContent side="right" onAnimationEndCapture={handleAnimationEnd}>
        <SheetHeader>
          <InformationsTitle>
            <Typography as="span" $variant="body-3-medium">
              {card.cardholderName}
            </Typography>
            <span style={{ margin: "0 auto" }}>
              <IssuingCardBadge card={card} />
            </span>
          </InformationsTitle>
        </SheetHeader>
        <ScrollArea height="calc(100vh - 6rem)" style={{ paddingBottom: "1rem" }}>
          <ContentContainer>
            <IssuingCardWithActions card={card} onOpenBlockModal={() => setModalOpen(true)} />
            <FullSeparatorDashed />
            <IssuingCardUsage card={card} />
            <Actions>
              <Button size="medium" $variant="primary" onClick={goToCardDetails}>
                {t("issuing.card.transactions.showTransactions")}
              </Button>

              {["ACTIVE", "PARTIALLY_ACTIVE"].includes(card.status) && (
                <>
                  <Button size="medium" $variant="danger" onClick={() => setModalOpen(true)}>
                    {t("issuing.card.action.block.stoleCard")}
                  </Button>
                </>
              )}
            </Actions>
          </ContentContainer>
        </ScrollArea>
        <LostCardModal cardId={cardId} isModalOpen={isModalOpen} onClose={() => setModalOpen(false)} />
      </SheetContent>
    </Sheet>
  )
}
