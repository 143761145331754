import { useApolloClient } from "@apollo/client"
import { Navigate } from "react-router-dom"
import { LEGACY_SIGNIN, ONBOARDING_LOGOUT_URL } from "../../env_variables"

export const Logout = () => {
  const client = useApolloClient()
  client.clearStore()

  localStorage.removeItem("token")

  if (LEGACY_SIGNIN) {
    return <Navigate to="/" />
  }

  window.location.href = ONBOARDING_LOGOUT_URL

  return <div></div>
}
