import { Button, Invoices3Icon, Typography } from "@hero/krypton"
import { useRef } from "react"
import styled from "styled-components"
import CenteredLoading from "../../../../00_shared/components/CenteredLoading"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useUploadFile } from "../requests/useUploadFile"
import { useCwtApplicationFormContext } from "./ApplicationForm.utils"

const ComponentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey.$100};
  padding: 2rem;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  background-image: url("/patterns/dots.png");
  background-repeat: repeat;
`

const EmptyStateContainer = styled.label<{ $error: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  transition: border 0.1s ease-in-out;

  ${({ $error, theme }) => $error && `border: 2px solid ${theme.colors.danger.$200}!important;`}

  &:hover {
    border: 2px dashed ${({ theme }) => theme.colors.grey.$400};
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-width: 16rem;
`

const EmptyStateIcon = styled.div`
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.grey.$100};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.grey.$300};
  position: relative;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const NonEmptyStateContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  position: relative;
`

const Separator = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grey.$200};
  position: relative;
  margin: 0.75rem 0;

  & > p {
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: ${({ theme }) => theme.typography["body-4-semibold"]};
    color: ${({ theme }) => theme.colors.grey.$500};
    padding: 0 0.5rem;
  }
`

export const UploadDocument = () => {
  const ref = useRef<HTMLInputElement>(null)
  const { t } = useDashboardTranslation()
  const { setValue, formState } = useCwtApplicationFormContext()

  const onUploadSuccess = (fp: string) => {
    setValue("filePath", fp)
  }

  const { uploadFile, isUploading, uploadedFilePath, uploadedFile } = useUploadFile({
    onUploadSuccess,
  })

  return (
    <ComponentWrapper>
      <input
        ref={ref}
        type="file"
        hidden
        name="dropzone-file"
        id="dropzone-file"
        style={{ visibility: "hidden" }}
        onChange={(e) => {
          if (e.target.files) {
            uploadFile(e.target.files[0])
          }
        }}
      />

      {uploadedFilePath && uploadedFile && !isUploading && (
        <NonEmptyStateContainer>
          <iframe
            src={`${URL.createObjectURL(uploadedFile)}#toolbar=0&navpanes=0`}
            title="document"
            width="100%"
            height="100%"
          />
          <Button
            isLoading={false}
            style={{
              position: "absolute",
              bottom: "2rem",
            }}
            onClick={() => {
              ref.current?.click()
            }}
          >
            {t("creditWireTransfer.uploadDocument.replaceInvoice")}
          </Button>
        </NonEmptyStateContainer>
      )}

      {isUploading && (
        <NonEmptyStateContainer>
          <CenteredLoading />
        </NonEmptyStateContainer>
      )}

      {!isUploading && !uploadedFilePath && (
        <EmptyStateContainer htmlFor="dropzone-file" $error={Boolean(formState.errors.filePath)}>
          <ContentContainer>
            <Center>
              <EmptyStateIcon>
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    background: "linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)",
                    transform: "scale(1.1)",
                  }}
                ></div>
                <Invoices3Icon
                  style={{
                    position: "relative",
                    height: "2rem",
                    width: "2.2188rem",
                  }}
                />
              </EmptyStateIcon>
            </Center>
            <Typography
              style={{
                margin: "0rem 1rem",
                textAlign: "center",
              }}
            >
              {t("creditWireTransfer.uploadDocument.dropInvoiceHere")}
            </Typography>
            <Separator>
              <Typography>{t("creditWireTransfer.uploadDocument.or")}</Typography>
            </Separator>
            <Button
              isLoading={false}
              type="button"
              size="medium"
              onClick={() => {
                ref.current?.click()
              }}
            >
              {t("creditWireTransfer.uploadDocument.selectInvoice")}
            </Button>
          </ContentContainer>
        </EmptyStateContainer>
      )}
    </ComponentWrapper>
  )
}
