import { Spinner, Table, TBody, THead } from "@hero/krypton"
import { DateTime } from "luxon"
import styled from "styled-components"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { IssuingTransaction } from "../../00_shared/business/IssuingTransaction"
import { IssuingCardTransactionLabel } from "./IssuingCardTransactionLabel"

const TableContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  border-top: none;
  display: grid;
  grid-template-rows: minmax(0, max-content) min-content;
  overflow-y: hidden;
`

const StaticTable = styled(Table)`
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: unset;
  border: none;

  thead tr th {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: ${({ theme }) => theme.colors.grey.$100};
    border-top: 1px solid ${({ theme }) => theme.colors.grey.$200};
  }

  thead tr th:first-child {
    border-top-left-radius: 1rem;
  }

  thead tr th:last-child {
    border-top-right-radius: 1rem;
  }
`

const CenteringTD = styled.td`
  text-align: center;
`

interface IssuingCardsTableProps {
  transactions: IssuingTransaction[]
  isLoading: boolean
}

export const IssuingCardTransactionsTable = ({ transactions, isLoading }: IssuingCardsTableProps) => {
  const { i18n, t } = useDashboardTranslation()
  const toLocaleDate = (date: string) => DateTime.fromISO(date).setLocale(i18n.language).toLocaleString()

  return (
    <TableContainer>
      <ScrollArea height="100%">
        <StaticTable>
          <THead>
            <tr>
              <th>{t("issuing.cards.transactions.table.transaction", { defaultValue: "Type" })}</th>
              <th>{t("issuing.cards.transactions.table.holder", { defaultValue: "Titulaire" })}</th>
              <th>{t("issuing.cards.transactions.table.amount", { defaultValue: "Dépensé ce mois-ci" })}</th>
            </tr>
          </THead>
          <TBody $clickable={!!transactions.length}>
            {isLoading && (
              <tr>
                <CenteringTD colSpan={5}>
                  <Spinner />
                </CenteringTD>
              </tr>
            )}
            {!transactions.length && !isLoading && (
              <tr>
                <CenteringTD colSpan={5}>{t("issuing.cards.transactions.table.noTransaction")}</CenteringTD>
              </tr>
            )}
            {transactions.map((transaction) => (
              <tr key={transaction.id}>
                <th>
                  <IssuingCardTransactionLabel transaction={transaction} />
                </th>
                <th>{transaction.amountEuros}</th>
                <th>{toLocaleDate(transaction.createdAt)}</th>
              </tr>
            ))}
          </TBody>
        </StaticTable>
      </ScrollArea>
    </TableContainer>
  )
}
