import { CheckIcon, Badge as DefaultBadge, ErrorIcon, RefreshIcon, theme } from "@hero/krypton"
import styled from "styled-components"
import { useCommonTranslation } from "../../../../01_technical/translations.js"
import { IssuanceStatus, IssuingCard } from "../business/IssuingCard.js"

interface IssuingCardBadgeProps {
  card: IssuingCard
}

const getBadgeStyles = (status: IssuanceStatus) => {
  switch (status) {
    case IssuanceStatus.ORDERED:
      return {
        borderColor: theme.colors.grey.$300,
        color: theme.colors.grey.$600,
        backgroundColor: theme.colors.grey.$100,
        icon: <RefreshIcon />,
      }
    case IssuanceStatus.DEACTIVATED:
      return {
        borderColor: theme.colors.danger.$300,
        color: theme.colors.danger.$300,
        backgroundColor: theme.colors.danger.$100,
        icon: <ErrorIcon />,
      }
    case IssuanceStatus.ACTIVE:
      return {
        borderColor: theme.colors.primary.$300,
        color: theme.colors.primary.$300,
        backgroundColor: theme.colors.primary.$100,
        icon: <CheckIcon color={theme.colors.primary.$300} />,
      }
    default:
      return {
        borderColor: theme.colors.primary.$300,
        color: theme.colors.primary.$300,
        backgroundColor: theme.colors.primary.$100,
        icon: null,
      }
  }
}

const Badge = styled(DefaultBadge)<{ $borderColor: string; $color: string; $backgroundColor: string }>`
  border: 1px solid ${({ $borderColor }) => $borderColor};
  color: ${({ $color }) => $color};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  height: auto;
  padding: 2px 12px 2px 12px;
`

export const IssuingCardBadge = ({ card }: IssuingCardBadgeProps) => {
  const { t } = useCommonTranslation()

  const { borderColor, color, backgroundColor, icon } = getBadgeStyles(card.status)

  return (
    <Badge $borderColor={borderColor} $color={color} $backgroundColor={backgroundColor}>
      {t(`issuing.card.status.${card.status}`)} {icon && icon}
    </Badge>
  )
}
