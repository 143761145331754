import { Toggle, Typography } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useIsFFBOV3Active } from "../../../00_shared/hooks/useFeatureFlag.hook"
import useLocalStorage from "../../../00_shared/hooks/useLocalStorage.hook"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 1rem;
  white-space: nowrap;
`

const Caption = styled(Typography)<{ mode: "dark" | "light" }>`
  color: ${({ theme, mode }) => (mode === "light" ? theme.colors.grey.$500 : theme.colors.white)};
`

export const BoSwitcher = ({ isReduced }: { isReduced: boolean }) => {
  const isFFBOV3Enabled = useIsFFBOV3Active()
  const [isBOV3, setIsBOV3] = useLocalStorage("BO_V3", false)
  const navigate = useNavigate()

  if (!isFFBOV3Enabled) {
    return null
  }

  return (
    <Container>
      <Caption $variant="body-4-medium" mode={Boolean(isBOV3) ? "light" : "dark"}>
        {!isReduced && Boolean(isBOV3) && "✨ New back-office ✨"}
        {!isReduced && !Boolean(isBOV3) && "Old back-office"}
      </Caption>

      <Toggle
        id="bo-switch"
        aria-label="switch-dynamic"
        checked={Boolean(isBOV3)}
        onClick={() => {
          setIsBOV3(!Boolean(isBOV3))
          navigate(!Boolean(isBOV3) ? "/accounts/transactions" : "/checkout_cash/cockpit")
          window.location.reload()
        }}
      />
    </Container>
  )
}
