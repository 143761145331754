import { Button, ErrorBlock, Typography, WizardHeader } from "@hero/krypton"
import styled from "styled-components"
import { eurosToCents, toEuros } from "../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { useAuthContext } from "../../../Auth/auth.context"
import { useNavigateWithScrolling } from "../../Collection/Link/Create/CreateLink.utils"
import { CwtApplicationFormValues } from "./components/ApplicationForm.utils"
import { useApplyForCreditWireTransfer } from "./requests/useApplyForCreditWireTransfer"
import { Beneficiary } from "./requests/useGetBeneficiaries"

interface CwtApplicationValidationProps {
  formValues: CwtApplicationFormValues
  goBack: () => void
  beneficiaries: Beneficiary[]
}

export const CwtApplicationValidation = ({ formValues, goBack, beneficiaries }: CwtApplicationValidationProps) => {
  const { t } = useDashboardTranslation()
  const navigate = useNavigateWithScrolling()

  const beneficiary = beneficiaries.find((b) => b.id === formValues.beneficiary.id)

  const [applyForCreditWireTransfer, { loading, error }] = useApplyForCreditWireTransfer()
  const { currentUser } = useAuthContext()
  const merchantId = currentUser.merchantId

  const onSubmit = async () => {
    await applyForCreditWireTransfer({
      variables: {
        merchantId,
        cwt: {
          amount: eurosToCents(formValues.amount),
          debtorIBAN: formValues.debtorIBAN,
          installmentCount: formValues.installmentsCount,
          label: formValues.label,
          recipient: {
            beneficiaryId: formValues.beneficiary.id,
            email: formValues.beneficiary.email,
            siret: formValues.beneficiary.siret,
            phone: formValues.beneficiary.phone,
            headquartersCountry: formValues.beneficiary.headquartersCountry,
            emailEnabled: true,
          },
          document: {
            path: formValues.filePath,
          },
        },
      },
    })

    navigate("/cwt")
  }

  return (
    <ScreenContainer>
      <WizardHeader
        onQuit={() => {
          navigate("/cwt")
        }}
        steps={[
          { name: t("creditWireTransfer.applicationForm.wizardStep1"), active: false, completed: true },
          { name: t("creditWireTransfer.applicationForm.wizardStep2"), active: true, completed: false },
        ]}
      />
      <PageWrapper>
        <Typography $variant="title-1-bold">{t("creditWireTransfer.application.validation.title")}</Typography>

        <Card>
          <CardHeader>
            <Typography $variant="body-4-medium">
              {t("creditWireTransfer.application.validation.cardSubtitle")}
            </Typography>
            <Typography $variant="title-2-bold">{toEuros(formValues.amount * 100)}</Typography>
          </CardHeader>
          <CardBody>
            <Row>
              <Typography $variant="body-4-regular">
                {t("creditWireTransfer.application.validation.supplier")}
              </Typography>
              <Typography $variant="body-4-medium">{beneficiary?.label}</Typography>
            </Row>
            <Row>
              <Typography $variant="body-4-regular">
                {t("creditWireTransfer.application.validation.contact")}
              </Typography>
              <div style={{ textAlign: "right" }}>
                <Typography $variant="body-4-medium">{beneficiary?.fullName}</Typography>
                <Typography $variant="caption-2">
                  {formValues.beneficiary.phone} - {formValues.beneficiary.email}
                </Typography>
              </div>
            </Row>
            <Row>
              <Typography $variant="body-4-regular">{t("creditWireTransfer.application.validation.debtor")}</Typography>
              <Typography $variant="body-4-medium">{formValues.debtorIBAN}</Typography>
            </Row>
            <Row>
              <Typography $variant="body-4-regular">
                {t("creditWireTransfer.application.validation.creditor")}
              </Typography>
              <Typography $variant="body-4-medium">{beneficiary?.iban}</Typography>
            </Row>
            <Row>
              <Typography $variant="body-4-regular">
                {t("creditWireTransfer.application.validation.reference")}
              </Typography>
              <Typography $variant="body-4-medium">{formValues.label}</Typography>
            </Row>
            <Row>
              <Typography $variant="body-4-regular">
                {t("creditWireTransfer.application.validation.financing")}
              </Typography>
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Typography $variant="body-4-medium">
                  {/* eslint-disable-next-line i18next/no-literal-string */}
                  {formValues.installmentsCount}x {t("creditWireTransfer.application.validation.installment")}
                </Typography>
                <Typography $variant="caption-2">
                  <span
                    style={{
                      whiteSpace: "pre-line",
                    }}
                  >
                    {t("creditWireTransfer.application.validation.installmentDescription", {
                      installmentCount: formValues.installmentsCount,
                    })}
                  </span>
                </Typography>
              </div>
            </Row>
          </CardBody>
        </Card>

        <Button type="button" size="medium" isLoading={loading} onClick={onSubmit}>
          {t("creditWireTransfer.application.validation.confirmButton")}
        </Button>

        {error && <ErrorBlock $margin="0">{error?.translatedMessage}</ErrorBlock>}

        <Center>
          <Button type="button" size="medium" $variant="underline" onClick={goBack}>
            {t("creditWireTransfer.application.validation.cancelButton")}
          </Button>
        </Center>
      </PageWrapper>
    </ScreenContainer>
  )
}

const ScreenContainer = styled.div`
  height: 100vh;
  background: ${({ theme }) => theme.colors.white};
`

const PageWrapper = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 32rem;
  margin: 3rem auto;
`

const Card = styled.section`
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
`

const CardHeader = styled.header`
  padding: 1.5rem;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.grey.$200};
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.colors.white} 0%,
    ${({ theme }) => theme.colors.grey.$100} 100%
  );
`

const CardBody = styled.div`
  padding: 0.5rem 1.5rem;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.$200};
  padding: 1rem 0;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
`

const Center = styled.div`
  display: flex;
  justify-content: center;
`
