import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

export function useCreateBeneficiary() {
  return useHeroMutation<CREATE_BENEFICIARY_SUCCESS_RESPONSE, CREATE_BENEFICIARY_PARAMS>({
    gqlQuerySchema: CREATE_BENEFICIARY_REQ,
  })
}

type CreateBeneficiaryInput = {
  fullName: string
  iban: string
  bic: string
  label: string
  bankName?: string
}

type CREATE_BENEFICIARY_PARAMS = {
  input: CreateBeneficiaryInput
}

type CREATE_BENEFICIARY_SUCCESS_RESPONSE = {
  success: boolean
  beneficiaryId: string
}

const CREATE_BENEFICIARY_REQ = gql`
  mutation createBeneficiary($input: CreateBeneficiaryInput!) {
    createBeneficiary(input: $input) {
      ... on CreateBeneficiaryOutput {
        success
        beneficiaryId
      }
      ... on SimpleApiError {
        errorCode
      }

      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`
