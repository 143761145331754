import { Navigate } from "react-router-dom"
import { AccountingScreen } from "./accounting.screen"
import { billingRoute } from "./billing/billing.route"
import { exportRoute } from "./export/export.route"

export const accountingRoute = {
  path: "tools/accounting/*",
  element: <AccountingScreen />,
  children: [exportRoute, billingRoute, { path: "*", element: <Navigate to={billingRoute.path} /> }],
}
