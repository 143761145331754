import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

const SEND_BA_EXTERNAL_WIRE = gql`
  mutation SendBAExternalWire(
    $beneficiaryId: String!
    $businessAccountLedgerId: String!
    $amount: Float!
    $reference: String
  ) {
    sendBAExternalWire(
      beneficiaryId: $beneficiaryId
      businessAccountLedgerId: $businessAccountLedgerId
      amount: $amount
      reference: $reference
    ) {
      ... on SendBAExternalWireOutput {
        beneficiaryName
        amount
      }
      ... on SimpleApiError {
        errorCode
      }
      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

type SendBAExternalWireOutput = {
  beneficiaryName: string
  amount: number
}

type SendBAExternalWireVariables = {
  beneficiaryId: string
  businessAccountLedgerId: string
  amount: number
  reference?: string
}

export const useSendBAExternalWire = () => {
  const [mutate, { data, loading, error }] = useHeroMutation<SendBAExternalWireOutput, SendBAExternalWireVariables>({
    gqlQuerySchema: SEND_BA_EXTERNAL_WIRE,
  })

  return { mutate, loading, error, data }
}
