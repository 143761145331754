import { useFeatureFlagContext } from "../../FeatureFlags/featureFlags.context"

const useFeatureFlag = (name: string) => {
  const ffContext = useFeatureFlagContext()
  const isFFActive = ffContext.featureFlags.find((ff) => ff.name === name)?.isActive

  return isFFActive
}

export const useIsFFMRFActive = () => {
  return useFeatureFlag("ADMIN.MARKETPLACE_RESERVE_FUNDING")
}

export const useIsFFCWTActive = () => {
  return useFeatureFlag("ALL.CWT")
}

export const useIsFFPINUPDATEActive = () => {
  return useFeatureFlag("DASHBOARD.PIN_UPDATE")
}

export const useIsFFBOV3Active = () => {
  return useFeatureFlag("DASHBOARD.BO_V3")
}

export const useIsBaUserMustBeOnboardedActive = () => {
  return useFeatureFlag("API.BA_USER_MUST_BE_ONBOARDED")
}
