import { theme } from "@hero/krypton"
import styled from "styled-components"
import { useCommonTranslation } from "../../../../01_technical/translations"
import { IssuanceStatus, IssuanceType, IssuingCard } from "../business/IssuingCard"
import CardPhysicalIllustration from "../icons/card-physical-front.png"
import CardVirtualIllustration from "../icons/card-virtual-front.png"
import IconBlock from "../icons/icon-block.png"
import IconLock from "../icons/icon-lock.png"
import IssuingCardOverlay from "./IssuingCardOverlay"

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: auto;
`

const CardActions = styled.div`
  display: flex;
  gap: 8px;
`

const Illustration = styled.div`
  position: relative;
`

const Action = styled.button`
  padding: 8px 8px 8px 12px;
  gap: 8px;
  border: none;
  background: transparent;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: ${theme.colors.black.$700};
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
`

interface IssuingCardDisplayWithActionsProps {
  card: IssuingCard
  onOpenBlockModal: () => void
  closeModal?: () => void
}

export const IssuingCardWithActions = ({ card, onOpenBlockModal, closeModal }: IssuingCardDisplayWithActionsProps) => {
  const { t } = useCommonTranslation()
  // const { lostCard, unblockCard } = { lostCard: () => undefined, unblockCard: () => undefined } //useCardContext();
  // const { showToast } = { showToast: () => undefined } //useToast();

  return (
    <CardContent>
      <Illustration>
        <img
          src={card.issuanceType === IssuanceType.PHYSICAL ? CardPhysicalIllustration : CardVirtualIllustration}
          alt="card"
          width={320}
          style={card.status === IssuanceStatus.DEACTIVATED ? { opacity: 0.5 } : {}}
        />
        <IssuingCardOverlay card={card} />
      </Illustration>

      <CardActions style={{ display: card.status === "ACTIVE" ? "flex" : "none" }}>
        <Action onClick={onOpenBlockModal}>
          <img width={20} src={IconLock} alt="lock" />
          {t("issuing.card.actions.block")}
        </Action>

        <Action onClick={onOpenBlockModal}>
          <img width={20} src={IconBlock} alt="block" />
          {t("issuing.card.action.block.stoleCard")}
        </Action>
      </CardActions>
    </CardContent>
  )
}
