import { useQuery } from "@apollo/client"
import { useIsFFMRFActive } from "../../../../00_shared/hooks/useFeatureFlag.hook"
import { unwrapGraphQLResponse } from "../../../../01_technical/requesting/DEPRECATED_graphql.errors"
import { GET_MRF_KPIS, GET_MRF_KPIS_ARGS, GET_MRF_KPIS_RESPONSE } from "../cockpitWithNewKPI.requests"

export const useGetMRFKpis = ({ accountId }: { accountId?: string }) => {
  const isMRRFeatureActive = useIsFFMRFActive()
  const {
    data: result,
    loading,
    error,
  } = useQuery<GET_MRF_KPIS_RESPONSE, GET_MRF_KPIS_ARGS>(GET_MRF_KPIS, {
    variables: {
      accountId,
    },
    skip: !isMRRFeatureActive,
  })

  const { data, error: functionalError } = unwrapGraphQLResponse(result?.merchantGetMarketplaceReserveFundingKPIs)

  return {
    data: data,
    loading,
    functionalError,
    technicalError: error,
  }
}
