import { Button, Modal as DefaultModal, toaster, Typography } from "@hero/krypton"
import { useEffect } from "react"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useIssuingCardInformationHook } from "../../IssuingCardList/IssuingCardInformations.hook"
import { useDeactivateCard } from "../hooks/useDeactivateCard.hook"

const Modal = styled(DefaultModal)`
  width: unset;
  max-height: unset;
  padding: 3rem:
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
`

interface LostCardModalProps {
  cardId: string
  isModalOpen: boolean
  onClose: () => void
}

export const LostCardModal = ({ cardId, isModalOpen, onClose }: LostCardModalProps) => {
  const { t } = useDashboardTranslation()
  const { deactivateCard, loading: deactivating, error: deactivateError } = useDeactivateCard()
  const { refetch } = useIssuingCardInformationHook(cardId)

  const handleDeactivateCard = async () => {
    if (cardId) {
      const deactivated = await deactivateCard(cardId)
      refetch()
      if (deactivated?.success) {
        toaster.success(t("issuing.cards.lostCardModal.success"))
      }
      onClose()
    }
  }

  useEffect(() => {
    if (deactivateError) {
      toaster.error(deactivateError.message)
    }
  }, [deactivateError])

  return (
    <Modal isOpen={isModalOpen} onClose={() => onClose()}>
      <Container>
        <div>
          <Typography $variant="title-2-bold">{t("issuing.cards.lostCardModal.title")}</Typography>
          <Typography $variant="body-4-regular">{t("issuing.cards.lostCardModal.description")}</Typography>
        </div>
        <Button size="medium" onClick={handleDeactivateCard} $variant="danger" isLoading={deactivating}>
          {t("issuing.cards.lostCardModal.confirm")}
        </Button>
      </Container>
    </Modal>
  )
}
