import { ApolloError } from "@apollo/client"
import { Button, ErrorBlock, Field, Spinner, toaster } from "@hero/krypton"
import { parsePhoneNumberFromString } from "libphonenumber-js/mobile"
import { ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { HeroApiError } from "../../../../01_technical/requesting/request-error.type"
import { useCommonTranslation, useDashboardTranslation } from "../../../../01_technical/translations"
import { use2faChallenge } from "../../../../Auth/Challenge2fa/use2faChallenge.hooks"
import { CenteringContainer } from "../component/CenteringContainer"
import { Footer } from "../component/Footer"
import { Panel } from "../component/Pannel"
import { Slot } from "../component/Slot"
import { User, useGetMerchantUserQuery } from "./useGetMerchantUserQuery.hooks"
import {
  UPDATE_MERCHANT_RESPONSE,
  UPDATE_MERCHANT_USER,
  UPDATE_MERCHANT_VARIABLES,
} from "./useUpdateMerchantUserMutation.requests"

export const UpdateUserForm = () => {
  const { data, loading, error } = useGetMerchantUserQuery()
  const { t: commonTranslations } = useCommonTranslation()
  const { t: dashboardTranslations } = useDashboardTranslation()
  const [error2fa, setError2fa] = useState<string | null>(null)

  const getTranslations = () => ({
    WRONG_FORMAT: commonTranslations("queryError.validationError"),
    EMAIL_ALREADY_TAKEN: dashboardTranslations("merchant.tools.setting.updateUser.emailAlreadyTaken"),
    DEVICE_NOT_FOUND: dashboardTranslations("merchant.tools.setting.updateUser.deviceNotFound"),
    NOT_FOUND: dashboardTranslations("merchant.tools.setting.updateUser.userNotFound"),
    UNAUTHORIZED: dashboardTranslations("merchant.tools.setting.updateUser.unauthorized"),
    "2FA_ABORTED": commonTranslations("auth.login.error.2faAborted"),
    "2FA_FAILED": commonTranslations("auth.login.error.2faFailed"),
  })
  const { mutationWith2fa: updateUser, mutationState: updateUserStatus } = use2faChallenge<
    UPDATE_MERCHANT_RESPONSE,
    UPDATE_MERCHANT_VARIABLES
  >(UPDATE_MERCHANT_USER, getTranslations())

  const [formState, setFormState] = useState<User>({
    id: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
  })
  const [phoneError, setPhoneError] = useState<string | null>(null)
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false)

  useEffect(() => {
    if (data) {
      setFormState(data)
    }
  }, [data])

  const saveUser = async (user: User) => {
    try {
      await updateUser({ ...user })
      toaster.success(dashboardTranslations("merchant.tools.setting.updateUser.success"))
    } catch (err) {
      if (err instanceof HeroApiError) {
        setError2fa(err.translatedMessage)
      }
      if (err === "2FA_ABORTED") {
        setError2fa(commonTranslations("auth.login.error.2faAborted"))
      }
      if (err === "2FA_FAILED") {
        setError2fa(commonTranslations("auth.login.error.2faFailed"))
      }
      if (err instanceof ApolloError && err.message === "NOT_FOUND") {
        setError2fa(dashboardTranslations("merchant.tools.setting.updateUser.deviceNotFound"))
      }
    }
  }

  const { t } = useTranslation()

  if (loading) {
    return (
      <CenteringContainer>
        <Spinner />
      </CenteringContainer>
    )
  }

  if (error) {
    return <CenteringContainer>{error.message}</CenteringContainer>
  }

  if (!data) {
    return <div>{t("merchant.tools.setting.updateUser.noUserError")}</div>
  }

  return (
    <>
      <Panel>
        {(
          [
            { field: "firstname", functional: t("merchant.tools.setting.updateUser.firstname.label"), type: "text" },
            { field: "lastname", functional: t("merchant.tools.setting.updateUser.lastname.label"), type: "text" },
            { field: "phone", functional: t("merchant.tools.setting.updateUser.phone.label"), type: "tel" },
            { field: "email", functional: t("merchant.tools.setting.updateUser.email.label"), type: "email" },
          ] as const
        ).map(({ field, functional, type }) => (
          <Slot key={field}>
            <Field
              type={type}
              fieldLabel={functional}
              placeholder={functional}
              value={formState[field]}
              $fullWidth
              onChange={(e: ChangeEvent<HTMLInputElement> | string) => {
                if (field === "phone") {
                  const value = e ? (e as string) : ""
                  setFormState({ ...formState, [field]: value })
                  const parsedPhoneNumber = parsePhoneNumberFromString(value)
                  if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                    setFormState({ ...formState, [field]: parsedPhoneNumber.number })
                    setPhoneError(null)
                    setIsPhoneValid(true)
                  } else if (value.length > 0) {
                    setPhoneError(t("merchant.tools.setting.updateUser.phone.error.mobileRequired"))
                    setIsPhoneValid(false)
                  } else {
                    setPhoneError(t("merchant.tools.setting.updateUser.phone.error.required"))
                    setIsPhoneValid(false)
                  }
                } else {
                  const value = (e as ChangeEvent<HTMLInputElement>).target.value
                  setFormState({ ...formState, [field]: value })
                }
              }}
              errorMessage={field === "phone" ? phoneError : undefined}
              defaultCountry={field === "phone" ? "FR" : undefined}
              aria-invalid={field === "phone" && isPhoneValid}
            />
          </Slot>
        ))}
      </Panel>

      <Footer>
        {error2fa && <ErrorBlock>{error2fa}</ErrorBlock>}

        <Button onClick={() => saveUser(formState)} isLoading={updateUserStatus.loading} disabled={!!phoneError}>
          {t("merchant.tools.setting.updateUser.submit")}
        </Button>
      </Footer>
    </>
  )
}
