import { Modal, Spinner, Typography } from "@hero/krypton"
import { useCommonTranslation } from "../../01_technical/translations"
import accessDeniedImage from "../../assets/access_denied.svg"

import styled from "styled-components"
import { SupportContact } from "../../00_shared/components/SupportContact"

const StyledSpinner = styled(Spinner)``

const Title = styled(Typography)`
  margin-bottom: 0.25rem;
`

const LoadingWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
`

const ImageWrapper = styled.div`
  height: 6.25rem;
  width: 6.25rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  padding: 18px;
`

interface Challenge2faModalProps {
  is2faModalOpen: boolean
  close2faModal: () => void
  phone: string
}

export const Challenge2faModal = ({ is2faModalOpen, close2faModal, phone }: Challenge2faModalProps) => {
  const { t } = useCommonTranslation()

  return (
    <Modal isOpen={is2faModalOpen} onClose={close2faModal}>
      <Wrapper>
        <ImageWrapper>
          <img src={accessDeniedImage} alt="" />
        </ImageWrapper>
        <Title $variant="title-3-semibold">{t("auth.2fa.modal.title")}</Title>

        {phone && <Title $variant="title-3-inter-bold">{phone}</Title>}
        <Typography>{t("auth.2fa.modal.description")}</Typography>
        <br />
        <LoadingWrapper>
          <StyledSpinner />
          <Typography $variant="caption-1">{t("auth.2fa.modal.redirect")}</Typography>
        </LoadingWrapper>
        <SupportContact />
      </Wrapper>
    </Modal>
  )
}
