import { WizardStep } from "@hero/krypton"
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { z, ZodError } from "zod"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { IssuanceType } from "../00_shared/business/IssuingCard"
import { ConfigureCard } from "./ConfigureCard/ConfigureCard.screen"
import { ConfirmCardCreation } from "./ConfirmCardCreation/ConfirmCardCreation.screen"
import { SelectIssuanceType } from "./SelectIssuanceType/SelectIssuanceType.screen"

type TranslationKeys =
  | "issuing.wizard.step.selectIssuanceType"
  | "issuing.wizard.step.configureCard"
  | "issuing.wizard.step.confirmCard"

interface StepConfig {
  path: string
  translationKey: TranslationKeys
  validation: z.ZodSchema
  element: ReactNode
}

const issuanceTypeValidation = z.object({
  cardDetails: z.object({
    cardType: z.nativeEnum(IssuanceType),
  }),
})

const cardConfigurationValidation = z.object({
  businessAccountId: z.string().startsWith("account-", { message: "Business account is required" }),
  businessAccountName: z.string(),
  cardholderId: z.string().startsWith("user-", { message: "Cardholder is required" }),
  cardholderFullname: z.string(),
  cardDetails: z
    .object({
      cardType: z.nativeEnum(IssuanceType),
      address: z
        .object({
          address1: z.string(),
          address2: z.string().optional(),
          zipCode: z.string(),
          city: z.string(),
          country: z.string(),
        })
        .optional(),
    })
    .refine(
      (data) => {
        // If cardType is PHYSICAL, ensure address is provided
        return data.cardType === IssuanceType.PHYSICAL ? !!data.address : true
      },
      {
        message: "Delivery address is required for physical card",
        path: ["address"],
      },
    ),
})

const schema = issuanceTypeValidation.merge(cardConfigurationValidation)

type CardCreationState = z.infer<typeof schema>

export const stepsConfig: StepConfig[] = [
  {
    path: "type",
    translationKey: "issuing.wizard.step.selectIssuanceType",
    validation: issuanceTypeValidation,
    element: <SelectIssuanceType />,
  },
  {
    path: "configure",
    translationKey: "issuing.wizard.step.configureCard",
    validation: cardConfigurationValidation,
    element: <ConfigureCard />,
  },
  {
    path: "confirm",
    translationKey: "issuing.wizard.step.confirmCard",
    validation: schema,
    element: <ConfirmCardCreation />,
  },
]

interface IssuingCardCreationContextType {
  state: CardCreationState
  setState: React.Dispatch<React.SetStateAction<CardCreationState>>
  steps: WizardStep[]
  setSteps: React.Dispatch<React.SetStateAction<WizardStep[]>>
  errors: Partial<Record<keyof CardCreationState, string>>
  setErrors: React.Dispatch<React.SetStateAction<Partial<Record<keyof CardCreationState, string>>>>
  handleNextStep: () => void
  goToPreviousStep: () => void
  validateAllPreviousSteps: () => void
  clearState: () => void
}

const IssuingCardCreationContext = createContext<IssuingCardCreationContextType | undefined>(undefined)

export const useIssuingCardCreationContext = () => {
  const context = useContext(IssuingCardCreationContext)
  if (!context) {
    throw new Error("useIssuingCardCreationContext must be used within an IssuingCardCreationProvider")
  }
  return context
}

const initialState: CardCreationState = {
  businessAccountId: "",
  businessAccountName: "",
  cardholderId: "",
  cardholderFullname: "",
  cardDetails: {
    cardType: IssuanceType.PHYSICAL,
  },
}

export const IssuingCardCreationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useDashboardTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const [state, setState] = useState<CardCreationState>(() => {
    const savedState = sessionStorage.getItem("issuingCardCreationState")
    return savedState ? JSON.parse(savedState) : initialState
  })

  const [errors, setErrors] = useState<Partial<Record<keyof CardCreationState, string>>>({})

  const saveState = useCallback((state: CardCreationState) => {
    sessionStorage.setItem("issuingCardCreationState", JSON.stringify(state))
  }, [])

  const clearState = useCallback(() => {
    sessionStorage.removeItem("issuingCardCreationState")
    setState(initialState)
  }, [])

  const generateSteps = useCallback((): WizardStep[] => {
    return stepsConfig.map((stepConfig) => ({
      name: t(stepConfig.translationKey),
      active: location.pathname.includes(stepConfig.path),
      completed: false,
    }))
  }, [location.pathname, t])

  const [steps, setSteps] = useState<WizardStep[]>(generateSteps())

  const validateStep = useCallback(
    (path: string): boolean => {
      try {
        const currentStep = stepsConfig.find((step) => step.path === path)
        currentStep?.validation.parse(state)
        setErrors({})
        return true
      } catch (error) {
        if (error instanceof ZodError) {
          const fieldErrors = error.errors.reduce(
            (acc, curr) => {
              acc[curr.path[0] as keyof CardCreationState] = curr.message
              return acc
            },
            {} as Partial<Record<keyof CardCreationState, string>>,
          )
          setErrors(fieldErrors)
        }
        return false
      }
    },
    [state],
  )

  const handleNextStep = useCallback(() => {
    const currentIndex = steps.findIndex((step) => step.active)
    if (currentIndex < steps.length - 1) {
      if (validateStep(stepsConfig[currentIndex].path)) {
        navigate(stepsConfig[currentIndex + 1].path)
      }
    }
  }, [steps, navigate, validateStep])

  const goToPreviousStep = useCallback(() => {
    const currentIndex = steps.findIndex((step) => step.active)
    if (currentIndex > 0) {
      navigate(stepsConfig[currentIndex - 1].path)
    }
  }, [steps, navigate])

  const validateAllPreviousSteps = useCallback((): void => {
    const currentIndex = steps.findIndex((step) => step.active)
    for (let i = 0; i < currentIndex; i++) {
      if (!validateStep(stepsConfig[i].path)) {
        navigate(stepsConfig[0].path)
        return
      }
    }
  }, [validateStep, steps, navigate])

  useEffect(() => {
    setSteps((prevSteps) =>
      prevSteps.map((step, index) => ({
        ...step,
        active: location.pathname.includes(stepsConfig[index].path),
      })),
    )
  }, [location.pathname, setSteps])

  useEffect(() => {
    saveState(state)
  }, [state, saveState])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      sessionStorage.setItem("redirectToFirstStep", "true")
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [clearState])

  useEffect(() => {
    const redirectToFirstStep = sessionStorage.getItem("redirectToFirstStep")
    if (redirectToFirstStep === "true") {
      clearState()
      navigate(stepsConfig[0].path)
      sessionStorage.removeItem("redirectToFirstStep")
    }
  }, [clearState, navigate])

  return (
    <IssuingCardCreationContext.Provider
      value={{
        state,
        setState,
        steps,
        setSteps,
        errors,
        setErrors,
        handleNextStep,
        goToPreviousStep,
        validateAllPreviousSteps,
        clearState,
      }}
    >
      {children}
    </IssuingCardCreationContext.Provider>
  )
}
