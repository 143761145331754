import { gql, useQuery } from "@apollo/client"
import * as Sentry from "@sentry/react"
import { createContext, useContext, useEffect, useState } from "react"
import { useIsBaUserMustBeOnboardedActive, useIsFFBOV3Active } from "../../00_shared/hooks/useFeatureFlag.hook"

type BusinessAccount = {
  name: string
  balance: number
  ledgerId: string
  virtualIbanId: string
  VIban: string
  BIC: string
  isMainAccount: boolean
  createdAt: string
}

type GetMerchantMainBusinessAccountResponse = {
  getMerchantMainBusinessAccount: BusinessAccount[]
}

const GET_MERCHANT_MAIN_BUSINESS_ACCOUNTS_QUERY = gql`
  query GetMerchantMainBusinessAccount {
    getMerchantMainBusinessAccount {
      ... on GetMerchantMainBusinessAccountOutput {
        businessAccount {
          name
          balance
          ledgerId
          virtualIbanId
          isMainAccount
          createdAt
        }
      }

      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

/**
 * @public
 */
export type BusinessAccountContextType = { isBaActive: boolean }

const BusinessAccountContext = createContext<BusinessAccountContextType>({} as BusinessAccountContextType)

export const useBusinessAccountContext = () => useContext(BusinessAccountContext)

export const BusinessAccountProvider: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  const isBAV3Active = useIsFFBOV3Active()

  const { data, error } = useQuery<GetMerchantMainBusinessAccountResponse>(GET_MERCHANT_MAIN_BUSINESS_ACCOUNTS_QUERY, {
    skip: !isBAV3Active,
  })
  const [isBaActive, setIsBaActive] = useState<boolean>(false)
  const isBaUserMustOnboardedFFActive = useIsBaUserMustBeOnboardedActive()

  useEffect(() => {
    if (data) {
      setIsBaActive(!!data.getMerchantMainBusinessAccount.length)
    }
  }, [data])

  useEffect(() => {
    if (error) {
      Sentry.captureException(error, {
        extra: {
          reason: "An error occurred while fetching the main business account verification",
        },
      })
    }
  }, [error])

  return (
    <BusinessAccountContext.Provider value={{ isBaActive: isBaUserMustOnboardedFFActive ? isBaActive : true }}>
      {children}
    </BusinessAccountContext.Provider>
  )
}
