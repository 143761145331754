import { gql, useLazyQuery } from "@apollo/client"

const GET_SIGNED_URL = gql`
  query getMerchantBusinessAccountOperationJustificativePresignedUrl($operationId: String!) {
    getMerchantBusinessAccountOperationJustificativePresignedUrl(operationId: $operationId) {
      ... on GetMerchantBusinessAccountOperationJustificativePresignedUrlOutput {
        signedUrl
        path
      }
      ... on GqlHeroError {
        errorCode
        message
      }
      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`

interface fetchSignedUrlOutput {
  signedUrl: string
  path: string
}

export const useOperationJustificativePresignedUrl = () => {
  const [getOperationJustificativePresignedUrl, { data, loading, error }] = useLazyQuery(GET_SIGNED_URL)

  const fetchSignedUrl = async (operationId: string) => {
    const result = await getOperationJustificativePresignedUrl({ variables: { operationId } })
    const data = result?.data?.getMerchantBusinessAccountOperationJustificativePresignedUrl
    return {
      signedUrl: data?.signedUrl,
      path: data?.path,
    } as fetchSignedUrlOutput
  }

  return {
    signedUrl: data?.getMerchantBusinessAccountOperationJustificativePresignedUrl?.signedUrl,
    path: data?.getMerchantBusinessAccountOperationJustificativePresignedUrl?.path,
    loading,
    error,
    fetchSignedUrl,
  }
}
