// SelectableCard.tsx
import { Badge, ChevronRightIcon, Typography } from "@hero/krypton"
import React from "react"
import styled from "styled-components"

const Card = styled.span<{ variant: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  cursor: ${({ variant }) => (variant === "disabled" ? "default" : "pointer")};
  background-color: ${({ variant, theme }) => (variant === "disabled" ? theme.colors.grey.$200 : theme.colors.white)};

  transition:
    box-shadow 0.2s,
    transform 0.2s;

  &:hover {
    box-shadow: ${({ variant, theme }) => (variant !== "disabled" ? theme.shadows.midHover : "none")};
    transform: ${({ variant }) => (variant !== "disabled" ? "scale(1.01)" : "none")};
  }
`

const CardContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const CardImageWrapper = styled.div`
  width: 3rem;
  height: 3rem;
`

const CardImage = styled.img`
  width: 3rem;
  height: 3rem;
`

const DisabledBadge = styled(Badge)`
  background: ${({ theme }) => theme.colors.grey.$300};
  color: ${({ theme }) => theme.colors.grey.$500};
  height: unset;
  padding: 0.1875rem 0.375rem 0.125rem 0.375rem;
`

interface SelectableCardProps {
  onClick?: () => void
  ImageComponent?: React.ElementType
  imageSrc?: string
  imageAlt?: string
  title: string
  description?: string
  variant?: "default" | "disabled"
  badgeText?: string
}

export const SelectableCard: React.FC<SelectableCardProps> = ({
  onClick,
  ImageComponent,
  imageSrc,
  imageAlt,
  title,
  description,
  variant = "default",
  badgeText,
}) => {
  return (
    <Card onClick={variant === "disabled" ? undefined : onClick} variant={variant}>
      <CardContent>
        <CardImageWrapper>
          {ImageComponent ? <ImageComponent /> : imageSrc && <CardImage src={imageSrc} alt={imageAlt || ""} />}
        </CardImageWrapper>
        <div>
          <Typography $variant="body-4-medium">{title}</Typography>
          {description && <Typography $variant="body-4-regular">{description}</Typography>}
        </div>
      </CardContent>
      {variant !== "disabled" ? <ChevronRightIcon /> : badgeText && <DisabledBadge>{badgeText}</DisabledBadge>}
    </Card>
  )
}
