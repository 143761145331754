import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

export function useApplyForCreditWireTransfer() {
  return useHeroMutation<CWT_APPLY_RESPONSE, CWT_APPLY_INPUT>({
    gqlQuerySchema: CWT_APPLY_REQ,
  })
}

type CWT_APPLY_INPUT = {
  merchantId: string
  cwt: {
    amount: number
    debtorIBAN: string
    installmentCount: number
    label: string
    recipient: {
      beneficiaryId: string
      email: string
      emailEnabled: boolean
      phone: string
      siret: string
      headquartersCountry: string
    }
    document: {
      path: string
    }
  }
}

type CWT_APPLY_RESPONSE = {
  success: boolean
}

export type GET_CREDIT_WIRE_TRANSFER_DOCUMENT_UPLOAD_URL_RESPONSE = {
  creditWireTransferDocumentUploadUrl: {
    signedUrl: string
    path: string
  }
}

const CWT_APPLY_REQ = gql`
  mutation creditWireTransferApply($merchantId: String!, $cwt: CWTApplyInput!) {
    creditWireTransferApply(merchantId: $merchantId, cwt: $cwt) {
      ... on CWTApplyOutput {
        success
      }
      ... on GqlHeroError {
        errorCode
        message
      }
      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`
