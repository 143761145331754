import { gql } from "@apollo/client"

export type ResetPasswordArgs = {
  password: string
  resetToken: string
}

export type ResetPasswordResponse = {
  resetPassword: boolean
}

export const RESET_PASSWORD = gql`
  mutation passwordReset($password: String!, $resetToken: String!) {
    resetPassword(password: $password, resetToken: $resetToken)
  }
`
